import React from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import "../Navbar/Navbar.css";
import outcomeImage from "../images/features/25.JPG";
import firstYear from "../images/Year1/final/3.jpg";
import Masonry from "./Masonry";

function Home() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const MyWorkObject = [
    {
      id: 1,
      title: "Standwork projects",
      desc: "",
      image: "https://i.postimg.cc/PJKnDvvs/37.jpg",
      url: "/standwork",
    },
    {
      id: 2,
      title: "University 1st year work.",
      desc: "",
      image: firstYear,
      url: "/first-year",
    },
    {
      id: 3,
      title: "Univserity 2nd year work.",
      desc: "",
      image: "https://i.postimg.cc/fyG958pT/finallineup2.jpg",
      url: "/second-year",
    },
    {
      id: 4,
      title: "My final outcomes.",
      desc: "",
      image: outcomeImage,
      url: "/final-outcomes",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>nisha - my portfolio</title>
      </Helmet>
      <div className="">
        <div className="max-w-8xl mx-auto ">
          <div className="min-h-screen flex items-center justify-center text-center ">
            <h1
              className="text-8xl text-gray-800 font-bold "
              data-aos="slide-down"
              data-aos-duration="500"
            >
              <span
                id="port"
                className="lg:text-9xl text-gray-800 text-center text-3xl"
              >
                My
              </span>
              <br />
              <span
                id="port"
                className="lg:text-9xl text-gray-800 text-center text-3xl "
              >
                Portfolio
              </span>
            </h1>
            <br />
            {/* <div className="absolute lg:top-3/4 top-2/3 left-1/2 transform -translate-x-1/2 mb-4">
              <button className="px-4 py-2 text-white bg-purple-500 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300">
                View Major Project
              </button>
            </div> */}
          </div>

          <section className="lg:p-20 p-2 min-h-screen" id="work">
            <h1
              className="text-9xl text-gray-900 font-bold text-right pb-2"
              id="me"
              data-aos="slide-down"
              data-aos-duration="1000"
            >
              <span>My work</span>
            </h1>
            <div className="flex flex-col md:flex-row gap-8">
              {MyWorkObject.map((header) => {
                return (
                  <div
                    className="max-w-sm bg-white shadow-md"
                    key={header.id}
                    data-aos="slide-down"
                    data-aos-duration="1500"
                  >
                    <NavLink to={header.url}>
                      <img
                        className="rounded-t-lg scale-90 hover:scale-100 transform transition ease-in-out duration-200"
                        src={header.image}
                        alt=""
                      />
                    </NavLink>
                    <div className="p-5">
                      <NavLink to={header.url}>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {header.title}
                        </h5>
                      </NavLink>
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        {header.desc}
                      </p>
                      <Link
                        to={header.url}
                        className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-purple-500 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 "
                      >
                        Read more
                        <svg
                          className="ml-2 -mr-1 w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          <section className="justify-center ml-auto mt-16 mb-16">
            <div className="video-responsive place-items-center grid">
              <iframe
                width="853"
                height="480"
                src="https://www.youtube.com/embed/sgqOV7YpOIs?mute=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </section>

          <section className="lg:p-20 p-2">
            <h1
              className="text-9xl text-gray-900 font-bold  pb-2 lg:text-left sm:text-center"
              id="me"
              data-aos="fade-down"
            >
              <span className="text-center mx-auto">Collections</span>
            </h1>
            <div class="lg:p-8 p-2 mx-auto flex flex-wrap">
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/RFtXTsKY/lineup.jpg"
                  class="shadow-lg h-72 scale-105 transform"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>

              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/pLNp13Hg/Slide5.jpg"
                  class="shadow-lg"
                  alt="work"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>

              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/kXR2sqkQ/Slide4.jpg"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/v8qx0Zfx/8.jpg"
                  class="shadow-lg"
                  alt="work"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>

              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/4N28dDLc/portfoliopage9.jpg"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/4y5hf4dd/8.jpg"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/htrFLjcm/Slide1.jpg"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/LXJrF3fk/Slide2.jpgs"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  src="https://i.postimg.cc/q7b9F5tM/Slide3.jpg"
                  class="shadow-lg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
