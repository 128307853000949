import React from "react";

function Personal() {
  return (
    <div className="lg:p-20 p-4">
      <h1
        className="text-9xl font-semibold text-gray-900 text-right pl-2 pb-10 pt-10"
        data-aos="slide-down"
        data-aos-duration="800"
        id="header"
      >
        Really wild animals
      </h1>
      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/GmM73sbX/moodboard.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/BnZ7GCf8/pattern1.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/zvn2qtfh/pattern2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/KjyQpP58/pattern4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/j2JvZ8f9/pattern3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Vk9GxHhP/placementprint.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class=" mx-auto p-4 ">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/RFtXTsKY/lineup.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </div>
  );
}

export default Personal;
