import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Three() {
  const row1 = [
    {
      image: "https://i.postimg.cc/PJKnDvvs/37.jpg",
    },
    {
      image: "https://i.postimg.cc/vH10f6Vr/38.jpg",
    },
    {
      image: "https://i.postimg.cc/brwFKCrW/39.jpg",
    },
    {
      image: "https://i.postimg.cc/qRMLRtrC/40.jpg",
    },
  ];

  const row2 = [
    {
      image: "https://i.postimg.cc/SQPrSNvJ/41.jpg",
    },
    {
      image: "https://i.postimg.cc/wMrFn9SR/42.jpg",
    },
    {
      image: "https://i.postimg.cc/8CW8H0fb/43.jpg",
    },
    {
      image: "https://i.postimg.cc/mD5Qg8V0/44.jpg",
    },
  ];

  const row3 = [
    {
      image: "https://i.postimg.cc/NfTPZHjg/45.jpg",
    },
    {
      image: "https://i.postimg.cc/rppjy0MR/46.jpg",
    },
    {
      image: "https://i.postimg.cc/8CsdQ2mh/47.jpg",
    },
    {
      image: "https://i.postimg.cc/4xWv0fVf/48.jpg",
    },
  ];

  const row4 = [
    {
      image: "https://i.postimg.cc/Hs49Q4L7/49.jpg",
    },
    {
      image: "https://i.postimg.cc/BnHNw9Nt/50.jpg",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>nisha - knit trims standwork</title>
      </Helmet>
      <section className="lg:p-20 p-5">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl ">
              <span className="relative inline-block">
                <h1
                  className="text-8xl text-gray-900 font-bold  pb-2 z-40 mx-auto"
                  id="header"
                  data-aos="slide-down"
                  data-aos-duration="800"
                >
                  <span>knit trims standwork </span>
                </h1>
              </span>
            </h2>
          </div>
          <div
            className="flex items-center sm:justify-center"
            data-aos="slide-down"
            data-aos-duration="2000"
          >
            <p className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
              Scroll for images.
            </p>
          </div>
        </div>

        <ul
          id="collections"
          className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 md:gap-8"
        >
          {row1.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row2.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row3.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row4.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}

export default Three;
