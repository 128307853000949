import React from "react";
import { Helmet } from "react-helmet";

function Major() {
  return (
    <div className="md:p-16 p-4">
      <Helmet>
        <title>nisha - major project</title>
      </Helmet>
      <h1
        className="lg:text-8xl text-5xl text-gray-900 text-left pt-10 md:pb-20 pb-8 pl-4"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Major Project Portfolio
      </h1>

      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/0QP6HxN6/1-Title-Page.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/8C8fMLQm/2where-Iam-From.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/gJLYDTVw/3gujarat-textiles.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/sXM2LXrg/4-Indian-culture.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/0NFvb0T9/5modern-VSold-page.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/vBh4Jj10/7-5mywestern-and-indianattire.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/J7KXvyZR/8-STANDWORK1.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/7hbZpLrL/9-STANDWORK2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/vmJRQHnB/10toile-1-version-1.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/65tX23m4/11toile-1-version-2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/L8WwT6NT/12toile-2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/4yXSdKxJ/13toile-2-changes.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/ry9HH9fz/14toile-3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/hPddj2Nt/15toile-3-changes.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/GmRx109C/16colourmoodboard.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Pq3WZPyX/18-5design-development4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/3rDX4rvh/20photoshoot.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/fRQ00xhj/21outfit1cad.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/50yHMgw1/22outfit2cad.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/L4rVNgph/23outfit3cad.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/mkMC0Vsk/24-Final-Design.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        {/* cade */}
        <div>
          <h1
            className="lg:text-6xl text-5xl text-gray-900 text-left pt-10 md:pb-14 pb-8 pl-4"
            data-aos="slide-down"
            data-aos-duration="800"
          >
            Specification Pack
          </h1>
        </div>

        <div className=" mx-auto flex flex-wrap">
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/d3xg2BWt/Nisha-Solanki-FSHN3205-Spec-Pack-01.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/RFGkF9Kt/Nisha-Solanki-FSHN3205-Spec-Pack-02.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/c1kqGxsy/Nisha-Solanki-FSHN3205-Spec-Pack-04.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
        {/* cade */}
        <div>
          <h1
            className="lg:text-6xl text-5xl text-gray-900 text-left pt-10 md:pb-14 pb-8 pl-4"
            data-aos="slide-down"
            data-aos-duration="800"
          >
            Major project final outcomes
          </h1>
        </div>

        <div className=" mx-auto flex flex-wrap">
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/QxcD3LVX/f8296363-6724-43f2-9a97-4beb53d54fe7.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/7LxQddJQ/0286004c-e66f-44d7-8925-f362b4620776.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/xjJY6N2N/f276d056-5911-45c9-ba95-5aac9fe73871.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Major;
