import React from "react";
import "../Navbar/Navbar.css";

export default function Masonry(props) {
  console.log(props);
  return (
    <div style={{ columns: props.columnCount, columnGap: 0 }}>
      {props.imageUrls.map((img, i) => (
        <img
          src={img}
          key={i}
          id="image"
          alt="gallery images"
          style={{ padding: props.gap / 2 }}
          data-aos="slide-up"
          data-aos-duration="1500"
          // data-aos-offset="500"
        />
      ))}
    </div>
  );
}
