import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";

function YearTwo() {
  const imageUrls = [
    "https://i.postimg.cc/qRNnNMJ3/IMG-2058.jpg",
    "https://i.postimg.cc/xCBmMD8F/IMG-2060.jpg",
    "https://i.postimg.cc/GhwYpCZ4/IMG-2061.jpg",
    "https://i.postimg.cc/gjRZKS6c/IMG-2064.jpg",
    "https://i.postimg.cc/SKsYPNzv/IMG-2068.jpg",
    "https://i.postimg.cc/L5dqhtQc/IMG-2072.jpg",
    "https://i.postimg.cc/P5fCLrK5/IMG-2075.jpg",
    "https://i.postimg.cc/mrJk634P/IMG-2076.jpg",
    "https://i.postimg.cc/FRkGJQN7/IMG_2149.png",
    "https://i.postimg.cc/K8Q06N0v/IMG_2150.png",
    "https://i.postimg.cc/N0MdBHn8/IMG_2151.png",
    "https://i.postimg.cc/L4Z6hZpT/IMG-2252.jpg",
    "https://i.postimg.cc/nhxLyVN1/IMG-3148.jpg",
    "https://i.postimg.cc/xCBmMD8F/IMG-2060.jpg",
    "https://i.postimg.cc/L4r5V3Tw/IMG-3149.jpg",
    "https://i.postimg.cc/5tN2t74Y/IMG-3151.jpg",
    "https://i.postimg.cc/SNCs1Y1s/IMG-3152.jpg",
    "https://i.postimg.cc/7LsYJ3hB/IMG-3153.jpg",
    "https://i.postimg.cc/xCBft9KM/IMG-3156.jpg",
    "https://i.postimg.cc/xCBmMD8F/IMG-2060.jpg",
    "https://i.postimg.cc/xCBmMD8F/IMG-2060.jpg",
    "https://i.postimg.cc/nLP0zL8y/IMG_2152.png",
    "https://i.postimg.cc/9Fd8pVT9/IMG_2153.png",
    "https://i.postimg.cc/vZyjFDMX/IMG_2249.jpg",
    "https://i.postimg.cc/Hk8thzDM/IMG-3518.jpg",
    "https://i.postimg.cc/SxnZpq8J/IMG-3516.jpg",
    "https://i.postimg.cc/Y08XtCbm/IMG-3517.jpg",
    "https://i.postimg.cc/PxybwFZg/IMG-3521.jpg",
    "https://i.postimg.cc/9MCtzj4P/IMG-3522.jpg",
    "https://i.postimg.cc/tCYdskgc/IMG-3524.jpg",
    "https://i.postimg.cc/wTZXLNyj/IMG-3525.jpg",
    "https://i.postimg.cc/tJ76VR96/IMG-3526.jpg",
    "https://i.postimg.cc/Y9CGJ2GP/IMG-3529.jpg",
    "https://i.postimg.cc/QxT9LyCj/IMG-3531.jpg",
    "https://i.postimg.cc/L5ZXpJHx/IMG-3534.jpg",
    "https://i.postimg.cc/7Z4gKkVF/IMG-3536.jpg",
    "https://i.postimg.cc/sXCkH1MY/finallineupillustrations.jpg",
    "https://i.postimg.cc/9QK5TLVN/ill.png",
    "https://i.postimg.cc/DwbYVnkB/IMG_4997.png",
    "https://i.postimg.cc/fTV8XDLD/IMG_5004.png",
    "https://i.postimg.cc/nL4Rz2Lm/IMG_5005.png",
    "https://i.postimg.cc/Gt5zNSXW/IMG_5006.png",
    "https://i.postimg.cc/yxkyFy7v/IMG_5016.png",
    "https://i.postimg.cc/fLHvtrQV/IMG_5017.png",
    "https://i.postimg.cc/YC1ff9wK/IMG_5018.png",
    "https://i.postimg.cc/ydrFY309/IMG_5019.png",
    "https://i.postimg.cc/MTG7Smfq/IMG_5021.png",
    "https://i.postimg.cc/s2LXMY0V/IMG_5022_(2).png",
    "https://i.postimg.cc/hv1c1TMQ/IMG_5023.png",
    "https://i.postimg.cc/YCqq8n5r/IMG_5024.png",
    "https://i.postimg.cc/x8D1R02h/IMG_5026.png",
    "https://i.postimg.cc/hPV7N5ZV/pagecover.jpg",
  ];

  const finalwork = [
    "https://i.postimg.cc/0yrB0kMZ/coverpage.jpg",
    "https://i.postimg.cc/nzQxRKXR/Docu-PROScan-2022-01-18-11-49-321024-1.jpg",
    "https://i.postimg.cc/8cRSD1BL/Docu-PROScan-2022-01-18-11-49-431024-1.jpg",
    "https://i.postimg.cc/X7gbzYvb/Docu-PROScan-2022-01-18-11-49-561024-1.jpg",
    "https://i.postimg.cc/9MT3tWks/Docu-PROScan-2022-01-18-11-50-081024-1.jpg",
    "https://i.postimg.cc/W4dczmwN/Docu-PROScan-2022-01-18-11-50-191024-1.jpg",
    "https://i.postimg.cc/bv4ctGmQ/Docu-PROScan-2022-01-18-11-50-361024-1.jpg",
    "https://i.postimg.cc/L6mdLF30/Docu-PROScan-2022-01-18-11-50-511024-1.jpg",
    "https://i.postimg.cc/s2C0dtgR/Docu-PROScan-2022-01-18-11-51-081024-1.jpg",
    "https://i.postimg.cc/3xwPfj6X/Docu-PROScan-2022-01-18-11-51-201024-1.jpg",
    "https://i.postimg.cc/dQWXcPZK/Docu-PROScan-2022-01-18-11-51-541024-1.jpg",
    "https://i.postimg.cc/3NBcnWFf/Docu-PROScan-2022-01-18-11-52-071024-1.jpg",
    "https://i.postimg.cc/8Pp3sBT2/Docu-PROScan-2022-01-18-11-52-191024-1.jpg",
    "https://i.postimg.cc/Cxt6v3b0/Docu-PROScan-2022-01-18-11-52-331024-1.jpg",
    "https://i.postimg.cc/wTwrNyv7/Docu-PROScan-2022-01-18-11-55-321024-1.jpg",
    "https://i.postimg.cc/bvtV4mRt/Docu-PROScan-2022-01-18-11-55-441024-1.jpg",
    "https://i.postimg.cc/PxnVH16F/Docu-PROScan-2022-01-18-11-56-001024-1.jpg",
    "https://i.postimg.cc/sXP6g1qf/Docu-PROScan-2022-01-18-11-56-121024-1.jpg",
    "https://i.postimg.cc/fywHtDQc/Docu-PROScan-2022-01-18-11-56-241024-1.jpg",
    "https://i.postimg.cc/BbMm2kJd/Docu-PROScan-2022-01-18-11-56-371024-1.jpg",
    "https://i.postimg.cc/rpZyYFQm/portfoliopage1.jpg",
    "https://i.postimg.cc/x1t9c1rR/portfoliopage2.jpg",
    "https://i.postimg.cc/CxFFhf25/portfoliopage3.jpg",
    "https://i.postimg.cc/4494BGtf/portfoliopage4.jpg",
    "https://i.postimg.cc/5NW4rrqw/portfoliopage5.jpg",
    "https://i.postimg.cc/CK2Ysrxn/portfoliopage6.jpg",
    "https://i.postimg.cc/PJsjBLZt/portfoliopage7.jpg",
    "https://i.postimg.cc/tgZ8pq97/portfoliopage8.jpg",
    "https://i.postimg.cc/4N28dDLc/portfoliopage9.jpg",
    "https://i.postimg.cc/gk2SGzDd/portfoliopage11.jpg",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>nisha - university 2nd year work</title>
      </Helmet>
      <div className="lg:p-20 p-4">
        <h1
          className="text-9xl text-gray-900 font-bold mx-auto pb-7 lg:p-2 pl-20"
          id="header"
        >
          <span>Uni 2nd year</span>
        </h1>
        <Masonry imageUrls={imageUrls} columnCount="3" gap="5"></Masonry>
        <Masonry imageUrls={finalwork} columnCount="3" gap="5"></Masonry>

        {/* outcomes */}

        <div className="lg:p-10 p-4">
          <h1 className="text-9xl text-gray-900 text-right mb-6" id="header">
            knitwear
          </h1>
          <div class="flex flex-wrap -m-1 md:-m-2">
            <div class="flex flex-wrap w-2/2 mx-auto">
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/445qMrx9/thumbnail_IMG_5103-removebg-preview.png"
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div
                class="w-2/2 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/xTGyjbZJ/IMG_6436.jpg"
                />
              </div>
              <div
                class="w-2/2 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/W1XGgcTs/IMG-6437.jpg"
                />
              </div>
            </div>
          </div>
          {/* test */}

          <div class="flex flex-wrap -m-1 md:-m-2">
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-2/2 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block mt-16 rounded-sm"
                  src="https://i.postimg.cc/PqsSN4qH/1.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block rounded-sm"
                  src="https://i.postimg.cc/G2bzxmYS/1-01.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/J046MzzB/2.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/sXzHHcFm/3.jpg"
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/5Ny7ryrh/4.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/jSJ8s9tv/cad-01.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/50m7hCbV/cad-02.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/x1SmzFL6/finallineup1.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/fyG958pT/finallineup2.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/x1SmzFL6/finallineup1.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-2">
          <h1
            className="text-9xl text-gray-900 font-bold mx-auto pb-10 z-50"
            id="header"
          >
            <span>Outcomes</span>
          </h1>
          <div class="grid grid-flow-col grid-rows-2 grid-cols-3 gap-8">
            <div>
              <img
                src="https://i.postimg.cc/KvpXKnns/IMG_3148.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div class="">
              <img
                src="https://i.postimg.cc/9QsvbCgd/IMG_3149.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="https://i.postimg.cc/7ZbkXBHk/IMG_3153.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="https://i.postimg.cc/9QSjkG0q/IMG_3158.jpg"
                alt=""
                loading="lazy"
              />
            </div>

            <div class="">
              <img
                src="https://i.postimg.cc/wMVp4ZR6/IMG_3152.jpg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YearTwo;
