import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// import images
import img1 from "../images/KnitToile/1.jpg";
import img2 from "../images/KnitToile/2.jpg";
import img3 from "../images/KnitToile/3.jpg";
import img4 from "../images/KnitToile/4.jpg";
import img5 from "../images/KnitToile/5.jpg";
import img6 from "../images/KnitToile/6.jpg";
import img7 from "../images/KnitToile/7.jpg";
import img8 from "../images/KnitToile/8.jpg";
import img9 from "../images/KnitToile/9.jpg";
import img10 from "../images/KnitToile/10.jpg";
import img11 from "../images/KnitToile/11.jpg";
import img12 from "../images/KnitToile/12.jpg";
import img13 from "../images/KnitToile/13.jpg";
import img14 from "../images/KnitToile/14.jpg";
import img15 from "../images/KnitToile/15.jpg";
import img16 from "../images/KnitToile/16.jpg";
import img17 from "../images/KnitToile/17.jpg";
import img18 from "../images/KnitToile/18.jpg";
import img19 from "../images/KnitToile/19.jpg";
import img20 from "../images/KnitToile/20.jpg";
import img21 from "../images/KnitToile/21.jpg";
import img22 from "../images/KnitToile/22.jpg";
import img23 from "../images/KnitToile/23.jpg";
import img24 from "../images/KnitToile/24.jpg";
import img25 from "../images/KnitToile/25.jpg";
import img26 from "../images/KnitToile/26.jpg";
import img27 from "../images/KnitToile/27.jpg";
import img28 from "../images/KnitToile/28.jpg";
import img29 from "../images/KnitToile/29.jpg";
import img30 from "../images/KnitToile/30.jpg";
import img31 from "../images/KnitToile/31.jpg";
import img32 from "../images/KnitToile/32.jpg";
import img33 from "../images/KnitToile/33.jpg";
import img34 from "../images/KnitToile/34.jpg";
import img35 from "../images/KnitToile/35.jpg";
import img36 from "../images/KnitToile/36.jpg";
// more toiles
import img37 from "../images/KnitToile/37.jpg";
import img38 from "../images/KnitToile/38.jpg";
import img39 from "../images/KnitToile/39.jpg";
import img40 from "../images/KnitToile/40.jpg";
import img41 from "../images/KnitToile/41.jpg";
import img42 from "../images/KnitToile/42.jpg";
import img43 from "../images/KnitToile/43.jpg";
import img44 from "../images/KnitToile/44.jpg";
import img45 from "../images/KnitToile/45.jpg";
import img46 from "../images/KnitToile/46.jpg";
import img47 from "../images/KnitToile/47.jpg";
import img48 from "../images/KnitToile/48.jpg";
import img49 from "../images/KnitToile/49.jpg";
import img50 from "../images/KnitToile/50.jpg";

function KnitToile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-50 lg:p-10 p-4">
      <Helmet>
        <title>nisha - knitwear toile</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right lg:text-left pb-2 z-40 pl-13 lg:pl-2"
        id="header"
        data-aos="slide-right"
      >
        <span>Toile Knit</span>
      </h1>

      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "500" }}
              >
                About my toiles
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                To produce my final knit garment i had to create some toiles
                using knit pieces i created on a small mannequin. I wrapped the
                knit pieces around and experimented a lot with silhouette and
                shape and the way knit drapes on the body to get an
                understanding of how i want the silhouette of my knit sweater to
                look like.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src={img2}
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src={img30}
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-right"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src={img45}
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src={img48}
                alt="final outcome"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "500" }}
              >
                About my toiles
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                To produce the v neck trim i made a few trims using the 1 x 1, 2
                x 1 and 2 x 2 rib techniques and started to pin them, on my
                sweater that had the oversized silhouette that i wanted my final
                garment to be like, and experimented with the way i wanted my
                trims to overlap and lay which i went through a lot of trial and
                error with before i was happy with the final toile.
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="imageGrid">
        <img
          src={img1}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img2}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img3}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img4}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img5}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img6}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img7}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img8}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img9}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img10}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img11}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img12}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img13}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img14}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img15}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img16}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img17}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img18}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img19}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img20}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img21}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img22}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img23}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img24}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img25}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img26}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img26}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img27}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img28}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img29}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img30}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img31}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img32}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img33}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img34}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img36}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
      </main>

      {/* more toiles */}
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 z-40 mt-8"
        id="header"
        style={{ fontSize: "12vh" }}
        data-aos="slide-right"
      >
        <span>More Toiles</span>
      </h1>
      <main className="imageGrid mt-8">
        <img
          src={img37}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img38}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img39}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img40}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img41}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img42}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img43}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img44}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img45}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />

        <img
          src={img46}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img47}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img48}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img49}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
        <img
          src={img50}
          alt="Sample"
          data-aos="slide-up"
          data-aos-duration="1500"
        />
      </main>
    </div>
  );
}

export default KnitToile;
