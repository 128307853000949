import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../static/style.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// importing images
import img1 from "../images/summerproject/1.jpg";
import img2 from "../images/summerproject/2.jpg";
import img3 from "../images/summerproject/3.jpg";
import img4 from "../images/summerproject/4.jpg";
import img5 from "../images/summerproject/5.jpg";
import img6 from "../images/summerproject/6.jpg";
import img7 from "../images/summerproject/7.jpg";
import img8 from "../images/summerproject/8.jpg";
import img9 from "../images/summerproject/9.jpg";
import img10 from "../images/summerproject/10.jpg";
import img11 from "../images/summerproject/11.jpg";
import img12 from "../images/summerproject/12.jpg";
import img13 from "../images/summerproject/13.jpg";
import img14 from "../images/summerproject/14.jpg";
import img15 from "../images/summerproject/15.jpg";
import img16 from "../images/summerproject/16.jpg";
import img17 from "../images/summerproject/17.jpg";
import img18 from "../images/summerproject/18.jpg";
import cad1 from "../images/summerproject/cad1.jpg";
import cad2 from "../images/summerproject/cad2.jpg";
// import cad4 from "../images/summerproject/cad4.jpg";
// import cad5 from "../images/summerproject/cad5.jpg";
import sum1 from "../images/summerproject/spp/1.JPG";
import sum2 from "../images/summerproject/spp/2.JPG";
import sum3 from "../images/summerproject/spp/3.JPG";
import sum4 from "../images/summerproject/spp/4.JPG";

const imgs = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
];
const delay = 2500;

function SummerProject() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === imgs.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lg:p-14 p-4">
      <Helmet>
        <title>nisha - summer project</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 z-50"
        id="header"
        data-aos="slide-down"
        data-aos-duration="1000"
      >
        <span>Summer projects</span>
      </h1>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
      >
        <div>
          <img src={img1} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img2} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img3} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img4} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img5} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img6} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img7} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img8} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img9} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img10} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img11} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img12} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img14} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img15} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img16} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img17} alt="" style={{ height: "100vh" }} />
        </div>
        <div>
          <img src={img18} alt="" style={{ height: "100vh" }} />
        </div>
      </Carousel>
      ;
      <div className="mx-auto p-4 ">
        <div className="flex flex-wrap mt-4 ">
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img3}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img1}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img10}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img2}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img6}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img7}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mt-4 ">
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img11}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img16}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img13}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img17}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img15}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img4}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={cad1}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={cad2}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={sum2}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={sum3}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>

            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={sum1}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-2/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={sum4}
                data-aos="slide-up"
                data-aos-duration="1500"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummerProject;
