import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Portfolios() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="lg:p-20 p-4">
      <Helmet>
        <title>nisha - portfolio</title>
      </Helmet>
      <div className="h-72">
        <h1
          className="text-9xl font-semibold text-gray-900 text-center p-10 pb-0 lg:pt-1 pt-20"
          data-aos="slide-down"
          data-aos-duration="800"
          id="header"
        >
          Portfolios
        </h1>
      </div>

      <h1
        className="lg:text-7xl text-5xl text-gray-900 text-left pt-10 pb-18"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Deconstruction Patchwork
      </h1>

      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/0yrB0kMZ/coverpage.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/qBYHmsFV/portfoliopage1.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/x1t9c1rR/portfoliopage2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/CxFFhf25/portfoliopage3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/4494BGtf/portfoliopage4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/5NW4rrqw/portfoliopage5.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/CK2Ysrxn/portfoliopage6.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/PJsjBLZt/portfoliopage7.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/tgZ8pq97/portfoliopage8.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/4N28dDLc/portfoliopage9.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-2/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/g0DP41CN/portfoliopage9.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>

      <h1
        className="text-7xl text-gray-900 text-right p-10 pb-0 pr-0"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Dries Van Noten 2008 Fall RTW
      </h1>
      <h1
        className="text-4xl text-right p-10 pr-0 pt-3"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Group Project
      </h1>
      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/gcvVtmG1/1.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/m2mFKrJp/2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/dt68v6kB/3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/j2K7YnRm/4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/cJgYRND9/5.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/C1bq2spb/6.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Bnv2vF2G/7.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/SNSMZ9Tz/9.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-2/3 p-4 mx-auto">
          <img
            src="https://i.postimg.cc/4y5hf4dd/8.jpg"
            class="shadow-lg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
      {/* knitwear */}

      <h1
        className="text-7xl text-gray-900 text-left pt-20 pb-18"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Who I am? Knitwear Project
      </h1>
      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/8PzBCYBM/1.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/brz0kVXX/2.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/8C6bSrdY/3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/pL9ffnSd/4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/85GLgXzF/5.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/3Jfj0Zn6/6.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/gkj8pZmK/7.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/v8qx0Zfx/8.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/tgQxFvPr/9.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/T14y1tfJ/10.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/NFp648YK/11.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-2/3 p-4 mx-auto">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Kz5BG710/12.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <h1
        className="text-7xl text-gray-900 text-right pt-20 pb-18"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Capsule portfolio
      </h1>
      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/NMBZdQgW/theme2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/pdDgQmtf/theme.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/SKCpZZcz/marketresearch.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/DzV9bqRf/marketresearch2.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/65qxs6dK/richandpoor4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/m2pRh1bh/designs5.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/J0hvWKHx/toile.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/FRxXv7Wr/colour-yarn6.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/2ycpqgNg/finallineup.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/P58Gx63F/finalgarment.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/P5LHDfxy/cad.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <h1
        className="text-7xl text-gray-900 text-left pt-20 pb-18"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        D&A Portfolio
      </h1>
      <div class=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/J4CjYmjM/1research-visuals.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/MK1mzXzn/2.jpg"
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Y25xcQBc/3.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/25940Z7K/4.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/MTh1SJ40/5.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/C5kkM6T3/6.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/K8N7gVkF/7.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/fW9xvkt8/8.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/521qy1tn/9.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </div>
  );
}

export default Portfolios;
