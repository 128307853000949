import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";
// loading images

function YearOne() {
  const imageUrls = [
    "https://i.postimg.cc/Gmrqxn9t/1.jpg",
    "https://i.postimg.cc/cLmTDk8m/2.jpg",
    "https://i.postimg.cc/k5gsLNQd/3.jpg",
    "https://i.postimg.cc/8c6tt1hJ/4.jpg",
    "https://i.postimg.cc/pXKsjLW3/5.jpg",
    "https://i.postimg.cc/wxyf6kSq/6.jpg",
    "https://i.postimg.cc/QN26VT3p/7.jpg",
    "https://i.postimg.cc/wB42zt2R/8.jpg",
    "https://i.postimg.cc/j5WXSs3g/9.jpg",
    "https://i.postimg.cc/RFjT3jjM/10.jpg",
    "https://i.postimg.cc/cCwBfFSf/11.jpg",
    "https://i.postimg.cc/3rv1MxvW/12.jpg",
    "https://i.postimg.cc/vHwzGwrh/13.jpg",
    "https://i.postimg.cc/x1tyGkWp/14.jpg",
    "https://i.postimg.cc/DyQcZp5T/15.jpg",
    "https://i.postimg.cc/SxyfzLtz/16.jpg",
    "https://i.postimg.cc/XvqKsjWj/17.jpg",
    "https://i.postimg.cc/W3K5GDJc/18.jpg",
    "https://i.postimg.cc/T2BD6j6X/1.jpg",
    "https://i.postimg.cc/dQhypHgB/2.jpg",
    "https://i.postimg.cc/s1FyNDqt/3.jpg",
    "https://i.postimg.cc/3w98xh0v/4.jpg",
    "https://i.postimg.cc/sxGsmZ6Y/5.jpg",
    "https://i.postimg.cc/XJzn9g8N/6.jpg",
    "https://i.postimg.cc/QttXBzcn/7.jpg",
    "https://i.postimg.cc/MKRjKc6f/8.jpg",
    "https://i.postimg.cc/kgmRVnG1/9.jpg",
    "https://i.postimg.cc/13rnwLN9/10.jpg",
    "https://i.postimg.cc/d0Q7B48G/11.jpg",
    "https://i.postimg.cc/cJDv5wm2/12.jpg",
    "https://i.postimg.cc/JnGsw0Yn/13.jpg",
    "https://i.postimg.cc/qRSzprWG/14.jpg",
    "https://i.postimg.cc/HnMjxKg1/15.jpg",
    "https://i.postimg.cc/Fsj7X2pj/16.jpg",
    "https://i.postimg.cc/HW8rBJ7v/17.jpg",
    "https://i.postimg.cc/Dz7fh00h/18.jpg",
    // coll23,
    // coll24,
    // coll25,
    // coll26,
    // coll27,
    // coll28,
    // coll29,
    // coll30,
    // coll31,
    // coll32,
    // coll33,
    // coll34,

    // coll4,
    // coll5,
    // coll6,
    // coll7,
    // coll8,
    // coll9,
    // coll10,

    // coll11,
    // coll12,
    // coll13,
    // coll14,

    // coll1,
    // coll2,
    // coll3,
    // coll15,
    // coll17,
    // coll18,
    // coll19,
    // coll20,
    // coll21,
    // coll22,
    // coll35,
    // coll36,
  ];

  const finalImages = [
    "https://i.postimg.cc/6QSMx26b/1.jpg",
    "https://i.postimg.cc/vBB3p7dV/2.jpg",
    "https://i.postimg.cc/7ZHs3rQ9/3.jpg",
    "https://i.postimg.cc/d0WzkVsG/4.jpg",
    "https://i.postimg.cc/HLj6N4m7/5.jpg",
    "https://i.postimg.cc/ZnvjqTnY/6.jpg",
    "https://i.postimg.cc/SxgZV1Bh/7.jpg",
    "https://i.postimg.cc/XJzQ3ZVD/8.jpg",
    "https://i.postimg.cc/qgfwh96s/9.jpg",

    // f1,
    // f2,
    // f3,
    // f4,
    // f5,
    // f6,
    // f7,
    // f8,
    // f9,
  ];

  const nishawork = [
    "https://i.postimg.cc/28yQxj70/1.jpg",
    "https://i.postimg.cc/Z5y61fg3/2.jpg",
    "https://i.postimg.cc/ydTmLkGb/3.jpg",
    "https://i.postimg.cc/FH2VztcD/4.jpg",
    "https://i.postimg.cc/sgBJ3fBM/5.jpg",
    "https://i.postimg.cc/65b0s4Qs/6.jpg",
    "https://i.postimg.cc/wBLcsWQH/7.jpg",
    "https://i.postimg.cc/Jn833bpX/8.jpg",
    "https://i.postimg.cc/CMN7NQTx/9.jpg",
    "https://i.postimg.cc/C5m4xzHq/10.jpg",
    "https://i.postimg.cc/qqM8SpVM/11.jpg",
    "https://i.postimg.cc/QN0g5K1B/12.jpg",
    "https://i.postimg.cc/pdbKxfbS/13.jpg",
    "https://i.postimg.cc/Nfn1ChNB/14.jpg",
    // m1,
    // m2,
    // m3,
    // m4,
    // m5,
    // m6,
    // m7,
    // m8,
    // m9,
    // m10,
    // m11,
    // m12,
    // m13,
    // m14,
  ];

  const work = [
    "https://i.postimg.cc/X7NTn5cQ/1.jpg",
    "https://i.postimg.cc/SRd3WQq6/2.jpg",
    "https://i.postimg.cc/TYkB1PtG/3.jpg",
    "https://i.postimg.cc/VkpT9VyV/4.jpg",
    "https://i.postimg.cc/NFsPfhT5/5.jpg",
    "https://i.postimg.cc/25Wt07r8/6.jpg",
    "https://i.postimg.cc/MH93nsbt/7.jpg",
    "https://i.postimg.cc/3RHcV0xj/8.jpg",
    "https://i.postimg.cc/mZj5pDfM/9.jpg",
    "https://i.postimg.cc/76MWkP5F/10.jpg",
    "https://i.postimg.cc/ncLwyJpW/11.jpg",
    "https://i.postimg.cc/zXPP5cfG/12.jpg",
    "https://i.postimg.cc/6p2bQf7L/13.jpg",
    "https://i.postimg.cc/pLwG55zN/14.jpg",
    "https://i.postimg.cc/Y9kdbcdb/15.jpg",
    "https://i.postimg.cc/bv16xY9Z/16.jpg",
    "https://i.postimg.cc/cHmXzDjZ/17.jpg",
    "https://i.postimg.cc/4yxBR6kc/18.jpg",
    "https://i.postimg.cc/dQjbM6dh/19.jpg",
    "https://i.postimg.cc/XJMRWDQC/20.jpg",
    "https://i.postimg.cc/x8nBJNrj/21.jpg",
    "https://i.postimg.cc/gJvf2qTB/22.jpg",
    "https://i.postimg.cc/qvzS3dFK/23.jpg",

    // w1,
    // w2,
    // w3,
    // w4,
    // w5,
    // w6,
    // w7,
    // w8,
    // w9,
    // w10,
    // w11,
    // w12,
    // w13,
    // w14,
    // w15,
    // w16,
    // w17,
    // w18,
    // w19,
    // w20,
    // w21,
    // w22,
    // w23,
  ];

  const portfolios = [
    "https://i.postimg.cc/MZ1QzMKM/19.jpg",
    "https://i.postimg.cc/g0wxQrT0/20.jpg",
    "https://i.postimg.cc/Hn18QH3k/21.jpg",
    "https://i.postimg.cc/2yY31G8q/22.jpg",
    "https://i.postimg.cc/k4bt6DQH/23.jpg",
    "https://i.postimg.cc/jjCfTMLt/24.jpg",
    "https://i.postimg.cc/Ls718j1f/25.jpg",
    "https://i.postimg.cc/52szDh9s/26.jpg",
    "https://i.postimg.cc/fW7J6GBY/28.jpg",
    "https://i.postimg.cc/MH0M54rb/29.jpg",
    "https://i.postimg.cc/rwHm1Mw2/31.jpg",
    "https://i.postimg.cc/tT34G6t9/32.jpg",
    "https://i.postimg.cc/Pq8qK9Cp/33.jpg",
    "https://i.postimg.cc/k43gHsSj/34.jpg",
    "https://i.postimg.cc/CLNnf38w/35.jpg",
    "https://i.postimg.cc/7ZZG1K10/36.jpg",
    "https://i.postimg.cc/pLc9sKk7/37.jpg",
    "https://i.postimg.cc/KYd12F6D/38.jpg",
    "https://i.postimg.cc/28PMhtNX/39.jpg",
    "https://i.postimg.cc/Kj5jBTR0/40.jpg",
    "https://i.postimg.cc/BQqXsmVk/41.jpg",
    "https://i.postimg.cc/J4Pthbwn/43.jpg",
    "https://i.postimg.cc/sgjS6jzB/44.jpg",
    // p1,
    // p2,
    // p3,
    // p4,
    // p5,
    // p6,
    // p7,
    // p8,
    // p18,
    // p19,
    // p20,
    // p21,
    // p22,
    // p23,
    // p24,
    // p25,
    // p9,
    // p10,
    // p11,
    // p13,
    // p14,
    // p15,
    // p16,
    // p17,
    // p26,

    "https://i.postimg.cc/Y0vDm6nH/1.jpg",
    "https://i.postimg.cc/C1LQwTbd/2.jpg",
    "https://i.postimg.cc/Y0TnNvZm/3.jpg",
    "https://i.postimg.cc/xCxFCxP5/4.jpg",
    // p30,
    // p31,
    // p32,
    // p33,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="min-h-screen lg:p-16 p-4">
        <Helmet>
          <title>nisha - university 1st year work</title>
        </Helmet>
        <h1
          className="text-9xl text-gray-900 font-bold mx-auto lg:pl-10 pb-3 pl-20"
          id="header"
          data-aos="slide-down"
          data-aos-duration="1000"
        >
          <span>Uni 1st year </span>
        </h1>

        <Masonry imageUrls={finalImages} columnCount="2" gap="20"></Masonry>
        <br />
        <Masonry imageUrls={nishawork} columnCount="3" gap="5"></Masonry>
        <br />
        <Masonry imageUrls={work} columnCount="2" gap="10"></Masonry>
        <br />
        <Masonry imageUrls={portfolios} columnCount="3" gap="15"></Masonry>
        <br />
        <Masonry imageUrls={imageUrls} columnCount="3" gap="5"></Masonry>
      </div>
    </div>
  );
}

export default YearOne;
