import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// images
// import img from "../images/CAD/1.jpg";
// import img2 from "../images/CAD/2.jpg";
// import img3 from "../images/CAD/3.jpg";
// import img4 from "../images/CAD/4.jpg";
import img5 from "../images/CAD/5.jpg";
// import img6 from "../images/CAD/6.jpg";
// import img7 from "../images/CAD/7.jpg";
// import img8 from "../images/CAD/8.jpg";
// import img12 from "../images/CAD/12.jpg";
// import img13 from "../images/CAD/13.jpg";

const row1 = [
  {
    image: "https://i.postimg.cc/G2MHVdhK/13.jpg",
  },
  {
    image: "https://i.postimg.cc/wT4QLYXh/2.jpg",
  },
  {
    image: "https://i.postimg.cc/LstPyc1Q/3.jpg",
  },
  {
    image: "https://i.postimg.cc/Zqt3xbCy/4.jpg",
  },
];

const row2 = [
  {
    image: "https://i.postimg.cc/qqbys23k/6.jpg",
  },
  {
    image: "https://i.postimg.cc/XvkZK9RT/12.jpg",
  },
  {
    image: "https://i.postimg.cc/4xtHdDB4/9.jpg",
  },
  {
    image: "https://i.postimg.cc/Cxfk8BLg/8.jpg",
  },
];

const row3 = [
  {
    image: "https://i.postimg.cc/Jz93Y3d0/1.jpg",
  },
  {
    image: "https://i.postimg.cc/Ls5jjLXw/cad-01.jpg",
  },
  {
    image: "https://i.postimg.cc/PrbbHd93/cad-02.jpg",
  },
  {
    image: "https://i.postimg.cc/zXYw4ytH/cad-03.jpg",
  },
];
const row4 = [
  {
    image: "https://i.postimg.cc/7hnkW1mx/tech-drawing-back-rib-direction.jpg",
  },
  {
    image: "https://i.postimg.cc/ZKLphCRn/tech-drawing-back.jpg",
  },
  {
    image: "https://i.postimg.cc/HsmXKQTF/tech-drawing-front.jpg",
  },
  {
    image: "https://i.postimg.cc/rwFWyM0J/tech-drawing-front-rib-direction.jpg",
  },
];
const row5 = [
  {
    image: "https://i.postimg.cc/y6LTfW79/back-outfit-1.jpg",
  },
  {
    image: "https://i.postimg.cc/0jXdrXVZ/front-outfit-1.jpg",
  },
  {
    image: "https://i.postimg.cc/Gttv0JbD/side-view-outfit-1.jpg",
  },
];
const row6 = [
  {
    image: "https://i.postimg.cc/cHmcGLYp/back-outfit-2.jpg",
  },
  {
    image: "https://i.postimg.cc/QxPJ3P9d/front-outfit-2.jpg",
  },
  {
    image: "https://i.postimg.cc/rwrCFJxC/side-view-outfit-2-0.jpg",
  },
];
const row7 = [
  {
    image: "https://i.postimg.cc/QCdJ8ZJH/back-outfit-3.jpg",
  },
  {
    image: "https://i.postimg.cc/tgd3F4rs/front-outfit-3.jpg",
  },
  {
    image: "https://i.postimg.cc/4NnQjZ30/side-view-outfit-3.jpg",
  },
];

function CAD() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>nisha - CAD work</title>
      </Helmet>
      <section className="lg:p-20 p-10">
        <h1
          className="text-9xl font-semibold text-gray-900 text-center p-10 pt-2 pb-0 lg:text-right"
          style={{ fontSize: "20vh" }}
          data-aos="slide-down"
          data-aos-duration="800"
          id="header"
        >
          CAD
        </h1>

        <ul
          id="collections"
          className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 md:gap-8"
        >
          {row1.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row2.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row3.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row4.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
        <ul
          id="collections"
          className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 md:gap-8"
        >
          {row5.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row6.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row7.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
      </section>

      <section className="w-full lg:p-20 p-2">
        <img
          src={img5}
          className="object-cover"
          alt="cad work"
          data-aos="slide-up"
          data-aos-duration="1000"
        />
      </section>
      <div className=" mx-auto flex flex-wrap lg:p-2 p-2">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/d3xg2BWt/Nisha-Solanki-FSHN3205-Spec-Pack-01.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/RFGkF9Kt/Nisha-Solanki-FSHN3205-Spec-Pack-02.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/c1kqGxsy/Nisha-Solanki-FSHN3205-Spec-Pack-04.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </div>
  );
}

export default CAD;
