import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function One() {
  const row1 = [
    {
      image: "https://i.postimg.cc/RFdQmf0y/1.jpg",
    },
    {
      image: "https://i.postimg.cc/TYgj2Zhn/12.jpg",
    },
    {
      image: "https://i.postimg.cc/Vvb6gQ2g/14.jpg",
    },
    {
      image: "https://i.postimg.cc/TwBmf96k/15.jpg",
    },
  ];

  const row2 = [
    {
      image: "https://i.postimg.cc/3xryM6G8/16.jpg",
    },
    {
      image: "https://i.postimg.cc/8PSJ4sPr/17.jpg",
    },
    {
      image: "https://i.postimg.cc/SNdsGrvw/18.jpg",
    },
    {
      image: "https://i.postimg.cc/g0nnjZ41/19.jpg",
    },
  ];

  const row3 = [
    {
      image: "https://i.postimg.cc/LsMzZvYG/2.jpg",
    },
    {
      image: "https://i.postimg.cc/P56Cd4bm/20.jpg",
    },
    {
      image: "https://i.postimg.cc/0yNNXSgr/21.jpg",
    },
    {
      image: "https://i.postimg.cc/rFMsWbQ1/22.jpg",
    },
  ];

  const row4 = [
    {
      image: "https://i.postimg.cc/br8yxDHy/23.jpg",
    },
    {
      image: "https://i.postimg.cc/k4PqC6HQ/24.jpg",
    },
    {
      image: "https://i.postimg.cc/yYL1RNJK/26.jpg",
    },
    {
      image: "https://i.postimg.cc/65pwBNMm/27.jpg",
    },
  ];

  const row5 = [
    {
      image: "https://i.postimg.cc/13gQNrh0/28.jpg",
    },
    {
      image: "https://i.postimg.cc/kGXrMxvb/29.jpg",
    },
    {
      image: "https://i.postimg.cc/Dy9Gt5gm/3.jpg",
    },
    {
      image: "https://i.postimg.cc/C5KtpwwC/30.jpg",
    },
  ];

  const row6 = [
    {
      image: "https://i.postimg.cc/W1ZyvWcy/31.jpg",
    },
    {
      image: "https://i.postimg.cc/HWPv7DpK/32.jpg",
    },
    {
      image: "https://i.postimg.cc/7LDKyqVC/33.jpg",
    },
    {
      image: "https://i.postimg.cc/90Sx3T8V/35.jpg",
    },
  ];

  const row7 = [
    {
      image: "https://i.postimg.cc/DzgBvRjx/36.jpg",
    },
    {
      image: "https://i.postimg.cc/7hczPNrs/4.jpg",
    },
    {
      image: "https://i.postimg.cc/nVwB1n94/7.jpg",
    },
    {
      image: "https://i.postimg.cc/P5HWC289/9.jpg",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>nisha - knit standwork</title>
      </Helmet>
      <section className="lg:p-20 p-5">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
          <div className="max-w-xl mb-2 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="mb-1 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl ">
              <span className="relative inline-block">
                <h1
                  className="text-9xl text-gray-900 font-bold  pb-2 z-40 mx-auto"
                  id="header"
                  data-aos="slide-down"
                  data-aos-duration="800"
                >
                  <span>knit standwork</span>
                </h1>
              </span>
            </h2>
          </div>
        </div>

        <ul
          id="collections"
          className="lg:mt-12 mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 md:gap-8"
        >
          {row1.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row2.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row3.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row4.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row5.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row6.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row7.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}

export default One;
