import React from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";

function Exhibitions() {
  const imageUrls = [
    "https://i.postimg.cc/xdPzzSVz/IMG-2220.jpg",
    "https://i.postimg.cc/rFdxYbxB/IMG-2221.jpg",
    "https://i.postimg.cc/3xmp3vL9/IMG-2222.jpg",
    "https://i.postimg.cc/Y0vGZGG6/IMG-2223.jpg",
    "https://i.postimg.cc/8cxJSpMq/IMG-2224.jpg",
    "https://i.postimg.cc/TYZg89cp/IMG-2227.jpg",
    "https://i.postimg.cc/zfNhvG6N/IMG-2232.jpg",
    "https://i.postimg.cc/QMZQ7bBR/IMG-2234.jpg",
  ];

  const more = [
    "https://i.postimg.cc/0yMJ5Hfp/IMG-0210.jpg",
    "https://i.postimg.cc/ncV4xkgk/IMG-2256.jpg",
    "https://i.postimg.cc/B6cF9LCk/IMG-2257.jpg",
    "https://i.postimg.cc/W1CgMP3W/IMG-2258.jpg",
    "https://i.postimg.cc/G2Q4S0L1/IMG-2259.jpg",
    "https://i.postimg.cc/qvP6NXbW/IMG-2260.jpg",
    "https://i.postimg.cc/sXVXWnpH/IMG-2262.jpg",
    "https://i.postimg.cc/Kc0KPypP/IMG-2263.jpg",
    "https://i.postimg.cc/pdMyWVyH/IMG-2264.jpg",
    "https://i.postimg.cc/yxGYws22/IMG-2265.jpg",
    "https://i.postimg.cc/pdvXN01V/IMG-2268.jpg",
    "https://i.postimg.cc/P5sdZ3Hb/IMG-2272.jpg",
    "https://i.postimg.cc/3RRh7zjh/IMG-2273.jpg",
    "https://i.postimg.cc/L6dsB6BZ/IMG-2275.jpg",
    "https://i.postimg.cc/RVcZP5Xp/IMG_2286.jpg",
    "https://i.postimg.cc/J4cRN3z9/IMG_2295.jpg",
    "https://i.postimg.cc/15PQ3grM/IMG_2297.jpg",
    "https://i.postimg.cc/MKD8MWsw/IMG_2303.jpg",
    "https://i.postimg.cc/pL64zyzw/IMG_2314.jpg",
    "https://i.postimg.cc/DyCM3mjS/IMG_2318.jpg",
    "https://i.postimg.cc/0QdVWNK2/IMG_2321.jpg",
    "https://i.postimg.cc/LXMy3PXb/IMG_2324.jpg",
    "https://i.postimg.cc/kGTfChy0/IMG_2327.jpg",
    "https://i.postimg.cc/RZkGvtcZ/IMG_2328.jpg",
    "https://i.postimg.cc/mkk7YZQn/IMG_2334.jpg",
    "https://i.postimg.cc/L81BQsrK/IMG_2337.jpg",
    "https://i.postimg.cc/FR9yKV1N/IMG_2344.jpg",
    "https://i.postimg.cc/50nwQQFv/IMG_2345.jpg",
    "https://i.postimg.cc/VvhnDygk/IMG_2346.jpg",
    "https://i.postimg.cc/h4ZLSxnR/IMG_2351.jpg",
    "https://i.postimg.cc/1zDnwnbX/IMG_2352.jpg",
  ];
  return (
    <div className="lg:p-12 p-4">
      <Helmet>
        <title>nisha - exhibitions</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 lg:pt-2 pt-16"
        id="header"
      >
        <span>Exhibitions </span>
      </h1>
      <h1
        className="text-5xl text-gray-900 text-left p-10 pl-0 pb-8"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        The Design Museum
      </h1>
      <Masonry imageUrls={imageUrls} columnCount="3" gap="5"></Masonry>
      <h1
        className="text-5xl text-gray-900 text-left p-10 pl-0 pb-8"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        Van Gogh - The Immersive Experience
      </h1>
      <Masonry imageUrls={more} columnCount="3" gap="5"></Masonry>
    </div>
  );
}

export default Exhibitions;
