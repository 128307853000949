import React from "react";
import { Helmet } from "react-helmet";

function Four() {
  const row1 = [
    {
      image: "https://i.postimg.cc/5tHxpR47/IMG-2058.jpg",
    },
    {
      image: "https://i.postimg.cc/MGhzLmXW/IMG-2060.jpg",
    },
    {
      image: "https://i.postimg.cc/5ym16cg0/IMG-2061.jpg",
    },
    {
      image: "https://i.postimg.cc/3JBJwGfB/IMG-2064.jpg",
    },
  ];

  const row2 = [
    {
      image: "https://i.postimg.cc/c4s0PH18/IMG-2068.jpg",
    },
    {
      image: "https://i.postimg.cc/ydc7C3S0/IMG-2072.jpg",
    },
    {
      image: "https://i.postimg.cc/ZYPJf3mS/IMG-2075.jpg",
    },
    {
      image: "https://i.postimg.cc/nrnf8Rxt/IMG-2077.jpg",
    },
  ];

  const row3 = [
    {
      image: "https://i.postimg.cc/02cPvsrX/IMG-2078.jpg",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>nisha - outerwear standwork</title>
      </Helmet>
      <section className="lg:p-20 p-5">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl ">
              <span className="relative inline-block">
                <h1
                  className="text-8xl text-gray-900 font-bold  pb-2 z-40 mx-auto"
                  id="header"
                  data-aos="slide-down"
                  data-aos-duration="800"
                >
                  <span>outerwear standwork </span>
                </h1>
              </span>
            </h2>
          </div>
          <div
            className="flex items-center sm:justify-center"
            data-aos="slide-down"
            data-aos-duration="2000"
          >
            <p className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
              Scroll for images.
            </p>
          </div>
        </div>

        <ul
          id="collections"
          className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 md:gap-8"
        >
          {row1.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row2.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row3.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}

export default Four;
