import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
// images
import image1 from "../images/Knitwear/1.jpg";
import image2 from "../images/Knitwear/2.jpg";
import image3 from "../images/Knitwear/3.jpg";
import image4 from "../images/Knitwear/4.jpg";
import image5 from "../images/Knitwear/5.jpg";
import image6 from "../images/Knitwear/6.JPG";
import image7 from "../images/Knitwear/7.JPG";
import image8 from "../images/Knitwear/8.jpg";
import image9 from "../images/Knitwear/9.jpg";
import image10 from "../images/Knitwear/10.jpg";
import image11 from "../images/Knitwear/11.jpg";
import image12 from "../images/Knitwear/12.jpg";
import image13 from "../images/Knitwear/13.jpg";
import image14 from "../images/Knitwear/14.jpg";
import image15 from "../images/Knitwear/15.jpg";
import image16 from "../images/Knitwear/16.jpg";
import image17 from "../images/Knitwear/17.jpg";
import image18 from "../images/Knitwear/18.jpg";
import image19 from "../images/Knitwear/19.jpg";
import image20 from "../images/Knitwear/20.jpg";
import image21 from "../images/Knitwear/21.jpg";
import image23 from "../images/Knitwear/23.jpg";
// outcome knitwear two
import knit1 from "../images/Knitwear/25.JPG";
import knit2 from "../images/Knitwear/26.JPG";
import wear1 from "../images/Finaloutcomes/KnitOutcome/1.jpg";
import wear2 from "../images/Finaloutcomes/KnitOutcome/2.jpg";
import wear3 from "../images/Finaloutcomes/KnitOutcome/3.jpg";
import wear4 from "../images/Finaloutcomes/KnitOutcome/4.jpg";
import wear5 from "../images/Finaloutcomes/KnitOutcome/5.jpg";
import wear13 from "../images/Finaloutcomes/KnitOutcome/13.jpg";

function Knitwear() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lg:p-20 p-3">
      <Helmet>
        <title>nisha - knitwear work</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 tracking-tight"
        id="header"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        <span>Knitwear work</span>
      </h1>

      <div class="p-8 mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={knit1}
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={knit2}
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={wear1}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={wear2}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={wear3}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={wear4}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={wear5}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="grid gap-4 lg:grid-cols-2 grid-cols-1 lg:w-2/3 w-3/3 p-4">
          <div>
            <div class="">
              <img
                class="shadow-lg"
                src="https://i.postimg.cc/Hsz0trkw/Slide1.jpg"
                alt="portfolio"
                data-aos="slide-up"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div>
            <div class="">
              <img
                src="https://i.postimg.cc/L5BtwXBM/Slide2.jpg"
                class="shadow-lg"
                alt="portfolio"
                data-aos="slide-up"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div>
            <div class="">
              <img
                src="https://i.postimg.cc/PJkbSZD4/Slide3.jpg"
                class="shadow-lg"
                alt="portfolio"
                data-aos="slide-up"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div>
            <div class="">
              <img
                class="shadow-lg"
                src="https://i.postimg.cc/xjK3cxrF/Slide4.jpg"
                alt="portfolio"
                data-aos="slide-up"
                data-aos-duration="1000"
              />
            </div>
          </div>
        </div>
      </div>
      {/* experiment */}
      <div class="lg:p-8 p-4 mx-auto flex flex-wrap">
        <h1
          className="text-7xl text-gray-900 font-bold text-left pb-2 "
          id="small"
          data-aos="slide-down"
          data-aos-duration="800"
        >
          <span>Experimental work</span>
        </h1>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image3}
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image2}
            alt="work"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>

        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image4}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image5}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image6}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image7}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image8}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image9}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image10}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image11}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image12}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image13}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image14}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image15}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image16}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image17}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image18}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image19}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image20}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image21}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src={image23}
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div className=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/Hs4SrYS8/IMG-3866.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/yd7RrR47/front.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/MKnQ4x4f/IMG-3885.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div>
        <h1
          className="lg:text-6xl text-5xl text-gray-900 text-left pt-10 md:pb-14 pb-8 pl-4"
          data-aos="slide-down"
          data-aos-duration="800"
        >
          Major project final outcomes
        </h1>
      </div>

      <div className=" mx-auto flex flex-wrap">
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/QxcD3LVX/f8296363-6724-43f2-9a97-4beb53d54fe7.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/7LxQddJQ/0286004c-e66f-44d7-8925-f362b4620776.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
        <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
          <img
            class="shadow-lg"
            src="https://i.postimg.cc/xjJY6N2N/f276d056-5911-45c9-ba95-5aac9fe73871.jpg"
            alt="portfolio"
            data-aos="slide-up"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </div>
  );
}

export default Knitwear;
