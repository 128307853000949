import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Two() {
  const row1 = [
    {
      image: "https://i.postimg.cc/76gxfcYc/a.jpg",
    },
    {
      image: "https://i.postimg.cc/kGsqybSv/aa.jpg",
    },
    {
      image: "https://i.postimg.cc/kXMngTr1/aaa.jpg",
    },
    {
      image: "https://i.postimg.cc/YqXtGQMp/aaaa.jpg",
    },
  ];

  const row2 = [
    {
      image: "https://i.postimg.cc/cL2ZTZv2/aaaaa.jpg",
    },
    {
      image: "https://i.postimg.cc/fRJDxkRY/aaaaaa.jpg",
    },
    {
      image: "https://i.postimg.cc/Xv5WzhFJ/aaaaaaa.jpg",
    },
    {
      image: "https://i.postimg.cc/KzybSNr5/b.jpg",
    },
  ];

  const row3 = [
    {
      image: "https://i.postimg.cc/9MthW7LH/bb.jpg",
    },
    {
      image: "https://i.postimg.cc/WzNPt1yF/bbb.jpg",
    },
    {
      image: "https://i.postimg.cc/kGTPSdrj/bbbb.jpg",
    },
    {
      image: "https://i.postimg.cc/02Hq2x0j/bbbbb.jpg",
    },
  ];

  const row4 = [
    {
      image: "https://i.postimg.cc/tCgjj7P8/bbbbbb.jpg",
    },
    {
      image: "https://i.postimg.cc/T3LvKSt9/bbbbbbb.jpg",
    },
    {
      image: "https://i.postimg.cc/t4KQW5fH/c.jpg",
    },
    {
      image: "https://i.postimg.cc/Pq1G4Dsv/cc.jpg",
    },
  ];

  const row5 = [
    {
      image: "https://i.postimg.cc/NMt3P0BJ/ccc.jpg",
    },
    {
      image: "https://i.postimg.cc/sgZbjW2H/ccccc.jpg",
    },
    {
      image: "https://i.postimg.cc/66YkYwSc/cccccc.jpg",
    },
    {
      image: "https://i.postimg.cc/HsNf1zT7/ccccccc.jpg",
    },
  ];

  const row6 = [
    {
      image: "https://i.postimg.cc/D09dBSKm/fetch.jpg",
    },
    {
      image: "https://i.postimg.cc/FRh6VnVw/pattern.jpg",
    },
    {
      image: "https://i.postimg.cc/W3SHW69z/pattern2.jpg",
    },
    {
      image: "https://i.postimg.cc/MHTrhM4D/work1.jpg",
    },
  ];

  const row7 = [
    {
      image: "https://i.postimg.cc/gcTMx0QC/work10.jpg",
    },
    {
      image: "https://i.postimg.cc/kgrfypnw/work11.jpg",
    },
    {
      image: "https://i.postimg.cc/7ZgXtnh4/work12.jpg",
    },
    {
      image: "https://i.postimg.cc/6Qcct6C1/work13.jpg",
    },
  ];
  const row8 = [
    {
      image: "https://i.postimg.cc/MTGDTkDZ/work14.jpg",
    },
    {
      image: "https://i.postimg.cc/SstGYZT6/work15.jpg",
    },
    {
      image: "https://i.postimg.cc/ZYxbm1Qv/work16.jpg",
    },
    {
      image: "https://i.postimg.cc/qq5Z0BHd/work3.jpg",
    },
  ];
  const row9 = [
    {
      image: "https://i.postimg.cc/FHcn5QP2/work5.jpg",
    },
    {
      image: "https://i.postimg.cc/509PCQxV/work6.jpg",
    },
    {
      image: "https://i.postimg.cc/vBP0L8B6/work7.jpg",
    },
    {
      image: "https://i.postimg.cc/vTkXkP5Y/work8.jpg",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>nisha - jersey with object standwork</title>
      </Helmet>
      <section className="lg:p-20 p-5">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl ">
              <span className="relative inline-block">
                <h1
                  className="text-7xl text-gray-900 font-bold  pb-2 z-40 mx-auto"
                  id="header"
                  data-aos="slide-down"
                  data-aos-duration="800"
                >
                  <span>jersey with object standwork </span>
                </h1>
              </span>
            </h2>
          </div>
          <div
            className="flex items-center sm:justify-center"
            data-aos="slide-down"
            data-aos-duration="2000"
          >
            <p className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
              Scroll for images.
            </p>
          </div>
        </div>

        <ul
          id="collections"
          className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 md:gap-8"
        >
          {row1.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row2.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row3.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row4.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row5.map(function (img) {
            return (
              // lang cards

              <li className="bg-white rounded-lg shadow-lg">
                <img
                  src={img.image}
                  className="scale-90 hover:scale-100 ease-in duration-200"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row6.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row7.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}

          {row8.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
          {row9.map(function (img) {
            return (
              // lang cards
              <li className="bg-white rounded-lg shadow-lg ">
                <img
                  src={img.image}
                  classname="scale-90 hover:scale-100 ease-in duration-200 "
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  alt="images"
                />
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}

export default Two;
