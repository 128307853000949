import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";

function YearThree() {
  const imageUrls = [
    "https://i.postimg.cc/Hs4SrYS8/IMG-3866.jpg",
    "https://i.postimg.cc/zX1tDbk1/IMG-3867.jpg",
    "https://i.postimg.cc/Xq9sc44h/IMG-3868.jpg",
    "https://i.postimg.cc/66ZbNLn0/IMG-3869.jpg",
    "https://i.postimg.cc/ydgQx057/IMG-3870.jpg",
    "https://i.postimg.cc/wjqwJCPf/IMG-3871.jpg",
    "https://i.postimg.cc/W15X79c2/IMG-3872.jpg",
  ];

  const bootcampSamples = [
    "https://i.postimg.cc/RhDgcBp5/IMG-1841.jpg",
    "https://i.postimg.cc/HWZvGwYZ/IMG-1842.jpg",
    "https://i.postimg.cc/wjJwsnhZ/IMG-1843.jpg",
    "https://i.postimg.cc/X7t2qny9/IMG-1844.jpg",
    "https://i.postimg.cc/W4vXmS83/IMG-1845.jpg",
    "https://i.postimg.cc/Kv1qm14Z/IMG-1847.jpg",
    "https://i.postimg.cc/fLHCNsNt/IMG-1848.jpg",
    "https://i.postimg.cc/SRJrQPSZ/IMG-1849.jpg",
    "https://i.postimg.cc/tgNDBPvz/IMG-1850.jpg",
    "https://i.postimg.cc/SsQd3swb/IMG-1851.jpg",
    "https://i.postimg.cc/66mcbBcF/IMG-1852.jpg",
  ];
  const capsules = [
    "https://i.postimg.cc/Sx8zSdmy/IMG-3883.jpg",
    "https://i.postimg.cc/x8Cz9S17/IMG-3884.jpg",
    "https://i.postimg.cc/MKnQ4x4f/IMG-3885.jpg",
    "https://i.postimg.cc/xTBzVhDx/IMG-3888.jpg",
    "https://i.postimg.cc/ryn4QzhL/IMG-3890.jpg",
    "https://i.postimg.cc/J02Bndyh/IMG-3891.jpg",
  ];
  const samples = [
    "https://i.postimg.cc/6ps0wzD0/IMG-2627.jpg",
    "https://i.postimg.cc/DznP40f4/IMG-2629.jpg",
    "https://i.postimg.cc/1zBcGS4C/IMG-2632.jpg",
    "https://i.postimg.cc/qv8G7yrd/IMG-2633.jpg",
    "https://i.postimg.cc/85CdwY61/IMG-2634.jpg",
    "https://i.postimg.cc/j2wHYJcm/IMG-2635.jpg",
    "https://i.postimg.cc/zBPCQkCT/IMG-2637.jpg",
    "https://i.postimg.cc/rsBSVFYm/IMG-2638.jpg",
    "https://i.postimg.cc/QNwgk0x3/IMG-2645.jpg",
    "https://i.postimg.cc/fRhx1y9q/IMG-2646.jpg",
    "https://i.postimg.cc/KzVTf8bN/IMG-2647.jpg",
    "https://i.postimg.cc/hjhxV7kv/IMG-2648.jpg",
    "https://i.postimg.cc/WbZG9ghJ/IMG-2648-removebg-preview.png",
    "https://i.postimg.cc/k5TKVyhL/IMG-2649.jpg",
    "https://i.postimg.cc/tgqFCcDh/IMG-2650.jpg",
    "https://i.postimg.cc/nrDDPfct/IMG-2651.jpg",
    "https://i.postimg.cc/G3H6qLGs/IMG-2652.jpg",
    "https://i.postimg.cc/hhRdFZfQ/IMG-2653.jpg",
    "https://i.postimg.cc/Hk4Qmm0Y/IMG-2653-removebg-preview.png",
    "https://i.postimg.cc/wxZZrCQ4/IMG-2654.jpg",
    "https://i.postimg.cc/Wz2B9T7d/IMG-2655.jpg",
    "https://i.postimg.cc/HsSghcRd/IMG-2656.jpg",
    "https://i.postimg.cc/RhXjyyPX/IMG-2657.jpg",
    "https://i.postimg.cc/W4Zcz931/IMG-2658.jpg",
    "https://i.postimg.cc/tgnpPqry/IMG-2658-removebg-preview.png",
    "https://i.postimg.cc/ZRFkFC6k/IMG-2659.jpg",
    "https://i.postimg.cc/yYNqc6d5/IMG-2660.jpg",
    "https://i.postimg.cc/dQ6PhZqr/IMG-2661.jpg",
    "https://i.postimg.cc/KzySMnkr/IMG-2662.jpg",
    "https://i.postimg.cc/FRxqkwQN/IMG-2663.jpg",
    "https://i.postimg.cc/8Pc3syXg/IMG-2664.jpg",
    "https://i.postimg.cc/8zr0m7Vy/IMG-2665.jpg",
    "https://i.postimg.cc/SRTvD8sP/IMG-2666.jpg",
    "https://i.postimg.cc/CL228j9B/IMG-2667.jpg",
    "https://i.postimg.cc/8CRXxxcM/IMG-2668.jpg",
    "https://i.postimg.cc/59nT4c3m/IMG-2669.jpg",
    "https://i.postimg.cc/MTz3wtf4/IMG-2670.jpg",
    "https://i.postimg.cc/j2d1HvsN/IMG-2670-removebg-preview.png",
    "https://i.postimg.cc/Bv9wYJSJ/Picture1.png",
    "https://i.postimg.cc/fyLHtLKf/Picture3.jpg",
    "https://i.postimg.cc/9M18QTGf/Pikjnh.jpg",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>nisha - university final year work</title>
      </Helmet>
      <div className="lg:p-20 p-4">
        <h1
          className="text-9xl text-gray-900 font-bold mx-auto pb-7 lg:p-2 pl-20"
          id="header"
        >
          <span>Uni 3rd year</span>
        </h1>
        <h1
          className="lg:text-7xl text-2xl text-gray-900 text-right mb-6"
          // id="header"
        >
          bootcamp final garment
        </h1>
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-wrap w-2/2 mx-auto">
            {imageUrls.slice(0, 8).map((imageUrl, index) => (
              <div
                className="w-1/3 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="1000"
                key={index}
              >
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-sm"
                  src={imageUrl}
                />
              </div>
            ))}
          </div>
        </div>
        <h1
          className="lg:text-7xl text-2xl text-gray-900 text-right mb-6"
          // id="header"
        >
          bootcamp samples
        </h1>
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-wrap w-2/2 mx-auto">
            {bootcampSamples.slice(0, 8).map((imageUrl, index) => (
              <div
                className="w-1/3 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="600"
                key={index}
              >
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-sm"
                  src={imageUrl}
                />
              </div>
            ))}
          </div>
        </div>

        {/* capsules */}

        <h1
          className="lg:text-7xl text-2xl text-gray-900 text-left mb-6 mt-12 font-weight-600"
          // id="header"
        >
          capsules
        </h1>
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-wrap w-2/2 mx-auto">
            {capsules.slice().map((imageUrl, index) => (
              <div
                className="w-1/3 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="5000"
                key={index}
              >
                <img
                  alt="gallery"
                  className="block object-scale-down object-center w-full h-full rounded-sm"
                  src={imageUrl}
                />
              </div>
            ))}
          </div>
        </div>

        {/* samples */}

        <h1
          className="lg:text-7xl text-2xl text-gray-900 text-left mb-6 mt-12 font-weight-600"
          // id="header"
        >
          D&A project
        </h1>
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-wrap w-2/2 mx-auto">
            {samples.slice().map((imageUrl, index) => (
              <div
                className="w-1/4 p-1 md:p-2"
                data-aos="slide-up"
                data-aos-duration="5000"
                key={index}
              >
                <img
                  alt="gallery"
                  className="block object-scale-down object-center w-full h-full rounded-sm"
                  src={imageUrl}
                />
              </div>
            ))}
          </div>
        </div>

        {/* <Masonry imageUrls={imageUrls} columnCount="4" gap="5"></Masonry> */}

        {/* outcomes */}
        {/* cade */}
        <div>
          <h1
            className="lg:text-6xl text-5xl text-gray-900 text-left pt-10 md:pb-14 pb-8 pl-4"
            data-aos="slide-down"
            data-aos-duration="800"
          >
            Major project final outcomes
          </h1>
        </div>

        <div className=" mx-auto flex flex-wrap">
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/QxcD3LVX/f8296363-6724-43f2-9a97-4beb53d54fe7.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/7LxQddJQ/0286004c-e66f-44d7-8925-f362b4620776.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/xjJY6N2N/f276d056-5911-45c9-ba95-5aac9fe73871.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
        {/* here */}
        <div className=" mx-auto flex flex-wrap">
          <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/XJtRRxbG/IMG-5171.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/wBGZNF3f/IMG-5174.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/jdHp1zk2/IMG-5176.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/LXg73f1H/IMG-5177.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default YearThree;
