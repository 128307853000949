import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../static/style.css";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen">
      <Helmet>
        <title>nisha - about me</title>
      </Helmet>
      <div className="flex flex-col container mx-auto pt-32 lg:p-20 p-1">
        <div className="flex">
          <div className="flex-none w-14 h-14" id="about"></div>
          <div className="max-w-6xl mx-auto">
            <div data-aos="slide-up" data-aos-duration="1000">
              <h1 className="text-5xl subheading font-semibold mb-4">About</h1>A
              talented, hardworking, and creative individual with vast
              experience in generating designs for many fashion product lines.
              Draws, sketches, and plans designs for fashion garments and
              accessories with ease. Knowledgeable about current fashion trends.
            </div>

            <div data-aos="slide-up" data-aos-duration="1100">
              <h1 className="text-5xl font-semibold subheading mt-16 mb-4">
                Education
              </h1>

              <ul className="mt-2">
                <li className="pt-2">
                  <p className="flex justify-between text-sm">
                    <strong className="text-2xl">
                      Soar Valley College • Leicester, Leicestershire
                    </strong>
                    06/2017
                  </p>
                  <p className="text-md font-semibold pt-2 pb-2">
                    Graphics, French, History, Home Economics, Maths, English
                  </p>
                  <p className="flex justify-between text-sm">
                    English literature/language 5 <br /> Maths 4 <br />
                    Additional Science B <br />
                    Science C <br /> Graphics B <br /> Religious Studies C{" "}
                    <br />
                    French C <br /> History C <br /> Home Economics(Food and
                    Nutrition) B
                  </p>
                </li>
                <li className="pt-2">
                  <p className="flex justify-between text-sm">
                    <strong className="text-2xl">
                      Gateway College Leicester • Leicester, Leicestershire
                    </strong>
                    05/2019
                  </p>
                  <p className="text-md font-semibold pt-2 pb-2">
                    Art and Design Level 3
                  </p>
                  <p className="flex justify-between text-sm">
                    Grades <br /> Year 1 D* D <br />
                    Year 2 D* DD
                  </p>
                </li>
              </ul>
            </div>

            <div data-aos="slide-up" data-aos-duration="1200">
              <h1 className="text-5xl font-semibold subheading mt-16 mb-4">
                Experience
              </h1>

              <ul className="mt-2">
                <li className="pt-2">
                  <p className="flex justify-between text-sm">
                    <strong className="text-base">Fashion Designer</strong>
                    2019-2021
                  </p>
                  <p className="flex justify-between text-base">
                    Assistant
                    <small>
                      Barcode Fashion Leicester • Leicester, Leicestershire
                    </small>
                  </p>
                  <p className="text-justify text-sm pt-2">
                    Packing, pressing, and photographing samples, as well as
                    assisting designers with research and organizing pattern
                    papers and sheets. Working with designers on upcoming
                    designs for brands such as Boohoo, Dorothy Perkins, and
                    Pretty Little Thing.
                  </p>
                </li>
              </ul>
            </div>

            <div data-aos="slide-up" data-aos-duration="1300">
              <h1 className="text-5xl font-semibold subheading mt-16 mb-4">
                About my work
              </h1>
              My portfolio work includes of designs I've created over the years,
              from college to my present years at university, as well as
              personal projects I've worked on. Standworks, knitwear, CAD,
              illustrations, final garments, and research are all included.
            </div>

            <div className="mt-16" data-aos="slide-up" data-aos-duration="1300">
              <h1 className="text-5xl font-semibold subheading mt-16 mb-4">
                CV
              </h1>
              <a
                href="/mycv"
                target="_blank"
                className="bg-purple-500/80 hover:bg-purple-500/90 text-white font-bold py-2 px-4 rounded inline-flex items-center mr-2"
              >
                <span>View My CV Online</span>
              </a>
              {/* <a
                href="https://fromsmash.com/NishaCV"
                target="_blank"
                className="bg-purple-500/80 hover:bg-purple-500/90 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Download</span>
              </a> */}
            </div>
          </div>
          <div className="flex-none w-14 h-14"></div>
        </div>
      </div>
    </div>
  );
}

export default About;
