import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import OutcomeGallery from "./OutcomeGallery";
import "../../static/style.css";

// images
import img1 from "../images/Finaloutcomes/1.jpg";
import img2 from "../images/Finaloutcomes/2.jpg";
import img3 from "../images/Finaloutcomes/3.jpg";
import img4 from "../images/Finaloutcomes/4.jpg";
import img5 from "../images/Finaloutcomes/5.jpg";
import img6 from "../images/Finaloutcomes/6.jpg";
import img7 from "../images/Finaloutcomes/7.jpg";
import img8 from "../images/Finaloutcomes/8.jpg";
// more images

import img9 from "../images/Finaloutcomes/One/1.jpg";
import img10 from "../images/Finaloutcomes/One/2.jpg";
import img11 from "../images/Finaloutcomes/One/3.jpg";
import img12 from "../images/Finaloutcomes/One/4.jpg";
import img13 from "../images/Finaloutcomes/One/5.jpg";
import img14 from "../images/Finaloutcomes/One/6.jpg";
import img15 from "../images/Finaloutcomes/One/7.jpg";
// knitwear outcomes
import wear1 from "../images/Finaloutcomes/KnitOutcome/1.jpg";
import wear2 from "../images/Finaloutcomes/KnitOutcome/2.jpg";
import wear3 from "../images/Finaloutcomes/KnitOutcome/3.jpg";
import wear4 from "../images/Finaloutcomes/KnitOutcome/4.jpg";
import knit1 from "../images/Knitwear/25.JPG";

function FinalOutcomes() {
  const imageUrls = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-6">
      <Helmet>
        <title>nisha - final outcomes</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 z-40"
        id="header"
        data-aos="slide-right"
      >
        <span>Final Outcomes</span>
      </h1>

      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Outerwear Deconstruction
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                My outerwear garment was influenced by deconstruction which
                contained patchwork as I wanted to incorporate sustainability
                and went with using pieces of fabric to create the coat. This
                final garment went through a lot of changes from the pattern to
                the 3D outcome.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src={img5}
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src="https://i.postimg.cc/D0KVS0rj/IMG-3155.jpg"
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-right"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex items-center justify-center w-full mt-2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 mb-5 lg:mb-0"
                src={img14}
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 pl-7 mt-5 lg:mt-0"
                src="https://i.postimg.cc/SRJvpPJL/IMG_4189.jpg"
                alt="final outcome"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2 mt-4 lg:mt-0">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Dries Van Noten's 2008 RTW collection garment
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                My knit final product was a group effort in which we
                individually made a piece of Dries Van Noten's 2008 RTW
                collection garment. This knit garment was made with dubied
                knitting machines.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Who am I?
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                My final knit garment is focused on me because the project was
                titled 'Who am I?' and I was motivated by my environment and
                activities. I made the knit sweater with a dubied knitting
                machine and went through a detailed process to make the v neck
                trims right. My love for sunsets and colour inspired the
                colours.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src={knit1}
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src="https://i.postimg.cc/mgRmCLDD/7_(3).jpg"
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-right"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex items-center justify-center w-full mt-2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 mb-5 lg:mb-0"
                src="https://i.postimg.cc/wjqwJCPf/IMG-3871.jpg"
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 pl-7 mt-5 lg:mt-0"
                src="https://i.postimg.cc/W15X79c2/IMG-3872.jpg"
                alt="final outcome"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2 mt-4 lg:mt-0">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Alaxander Mcqueen A/W2020
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                This is a bootcamp project
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Winter
              </h1>
              <p className="mt-4 text-gray-600 text-md">Capsule project</p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src="https://i.postimg.cc/yd7RrR47/front.jpg"
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src="https://i.postimg.cc/MKnQ4x4f/IMG-3885.jpg"
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Major project outfit 1
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                This project was on my culture and heritage - India
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src="https://i.postimg.cc/qRtfDS9H/Picture3.jpg"
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src="https://i.postimg.cc/zvWs8Wzd/Picture4.jpg"
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-right"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex items-center justify-center w-full mt-2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 mb-5 lg:mb-0"
                src="https://i.postimg.cc/7LxQddJQ/0286004c-e66f-44d7-8925-f362b4620776.jpg"
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pr-6 transition duration-300 scale-110 pl-7 mt-5 lg:mt-0"
                src="https://i.postimg.cc/RFYs30sj/d5bb684e-dcab-44fa-b652-23dd8cbb62cd.jpg"
                alt="final outcome"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2 mt-4 lg:mt-0">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Major project outfit 2
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                This project was on my culture and heritage - India
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className="bg-white mb-8 m-4"
        data-aos="slide-left"
        data-aos-duration="1000"
      >
        <div className="container px-6 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
            <div className="max-w-lg">
              <h1
                className="text-xl tracking-wide text-gray-800 lg:text-4xl subheading"
                style={{ fontWeight: "600" }}
              >
                Outcome - Major project outfit 3
              </h1>
              <p className="mt-4 text-gray-600 text-md">
                This project was on my culture and heritage - India
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2">
            <div className="flex flex-wrap justify-center ">
              <img
                className="max-w-2xl h-64 lg:h-96 pr-2 pb-2"
                src="https://i.postimg.cc/xjJY6N2N/f276d056-5911-45c9-ba95-5aac9fe73871.jpg"
                alt="final outcome"
              />
              <img
                className="max-w-2xl h-64 lg:h-96 pl-2 pb-2"
                src="https://i.postimg.cc/90GnvhbK/Picture10.jpg"
                alt="final outcome"
              />
            </div>
          </div>
        </div>
      </div>

      <section class="overflow-hidden text-gray-700">
        <div class=" px-1 py-2 mx-auto lg:pt-24 lg:px-2">
          <div class="flex flex-wrap -m-1 md:-m-2">
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full "
                  src={img1}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img2}
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img3}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img4}
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img5}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img6}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img7}
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img8}
                />
              </div>
            </div>
            {/* other images */}
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full "
                  src={img9}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img10}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img11}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img12}
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img13}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img14}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={img15}
                />
              </div>
            </div>

            {/* knitwear outcomes */}
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={wear1}
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={knit1}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={wear3}
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src={wear4}
                />
              </div>
            </div>

            {/* final garment*/}
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/Hs4SrYS8/IMG-3866.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/zX1tDbk1/IMG-3867.jpg"
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/Xq9sc44h/IMG-3868.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/wjqwJCPf/IMG-3871.jpg"
                />
              </div>
            </div>

            {/* other images */}
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full "
                  src="https://i.postimg.cc/yd7RrR47/front.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/Sx8zSdmy/IMG-3883.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/x8Cz9S17/IMG-3884.jpg"
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/MKnQ4x4f/IMG-3885.jpg"
                />
              </div>
              <div
                class="w-full p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/DZ5GVznL/IMG-3886.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/xTBzVhDx/IMG-3888.jpg"
                />
              </div>
              <div
                class="w-1/2 p-1 md:p-2"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-sm"
                  src="https://i.postimg.cc/ryn4QzhL/IMG-3890.jpg"
                />
              </div>
            </div>

            {/* major outcome*/}
            {/* cade */}
            <div>
              <h1
                className="lg:text-6xl text-5xl text-gray-900 text-left pt-10 md:pb-14 pb-8 pl-4"
                data-aos="slide-down"
                data-aos-duration="800"
              >
                Major project final outcomes
              </h1>
            </div>

            <div className=" mx-auto flex flex-wrap">
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/QxcD3LVX/f8296363-6724-43f2-9a97-4beb53d54fe7.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/7LxQddJQ/0286004c-e66f-44d7-8925-f362b4620776.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/3 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/xjJY6N2N/f276d056-5911-45c9-ba95-5aac9fe73871.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
            {/* here */}
            <div className=" mx-auto flex flex-wrap">
              <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/XJtRRxbG/IMG-5171.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/wBGZNF3f/IMG-5174.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/jdHp1zk2/IMG-5176.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
              <div class="w-1/1 sm:w-1/2 md:w-1/4 p-4">
                <img
                  class="shadow-lg"
                  src="https://i.postimg.cc/LXg73f1H/IMG-5177.jpg"
                  alt="portfolio"
                  data-aos="slide-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FinalOutcomes;
