import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";

function Standwork() {
  const MyWorkObject = [
    {
      id: 1,
      title: "knit standwork",
      image: "https://i.postimg.cc/3NHXhW5H/27.jpg",
      url: "/standwork/1",
    },
    {
      id: 2,
      title: "jersey with object standwork",
      image: "https://i.postimg.cc/0NsbzP0Y/aaaaaaa.jpg",
      url: "/standwork/2",
    },
    {
      id: 3,
      title: "knit trims standwork",
      image: "https://i.postimg.cc/zf37dsQR/46.jpg",
      url: "/standwork/3",
    },
    {
      id: 4,
      title: "outerwear standwork",
      image: "https://i.postimg.cc/5ym16cg0/IMG-2061.jpg",
      url: "/standwork/4",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="lg:p-20 p-4">
      <Helmet>
        <title>nisha - standwork</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 tracking-tight pt-1"
        id="me"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        <span>Standwork </span>
      </h1>
      <div className="flex flex-col md:flex-row gap-8 mt-10">
        {MyWorkObject.map((header) => {
          return (
            <div
              className="max-w-sm bg-white shadow-md"
              key={header.id}
              data-aos="slide-right"
              data-aos-duration="1500"
            >
              <NavLink to={header.url}>
                <img
                  className="scale-100 hover:scale-105 transform transition ease-in-out duration-200"
                  src={header.image}
                  style={{ height: "60vh" }}
                  alt=""
                />
              </NavLink>
              <div className="p-5">
                <NavLink to={header.url}>
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {header.title}
                  </h5>
                </NavLink>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {header.desc}
                </p>
                <Link
                  to={header.url}
                  className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-purple-500 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 "
                >
                  See more
                  <svg
                    className="ml-2 -mr-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Standwork;
