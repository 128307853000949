import React from "react";
import { Helmet } from "react-helmet";

function CV() {
  return (
    <div>
      <Helmet>
        <title>nisha - CV</title>
      </Helmet>
      <body className="flex justify-center content-center min-h-screen">
        <div className="py-10 px-10 w-4/5 mt-10 mb-10">
          <header>
            {/* <ul className="flex flex-wrap justify-end gap-2">
              <li>
                <a
                  className="bg-blue-600 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded"
                  target="blank"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    role="img"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path>
                    </g>
                  </svg>
                </a>
              </li>
            </ul> */}
            <div className="flex justify-between items-center">
              <div>
                <div className="bg-cover bg-no-repeat rounded-full h-52 w-52"></div>
              </div>
              <div className="grid justify-items-end">
                <h1 className="text-7xl font-extrabold" id="header">
                  Nisha Solanki
                </h1>
                <p className="text-xl mt-5">Aspiring Fashion Designer</p>
              </div>
            </div>
          </header>
          <main className="flex gap-x-10 mt-10">
            <div className="w-2/6">
              <strong className="text-xl font-semibold" id="sub">
                Contact Details
              </strong>
              <ul className="mt-2 mb-10">
                <li className="px-2 mt-1">
                  <strong className="mr-1">Phone </strong>
                  <a href="tel:+821023456789" className="block">
                    07479715635
                  </a>
                </li>
                <li className="px-2 mt-1">
                  <strong className="mr-1">E-mail </strong>
                  <a href="mailto:" className="block">
                    nishaa2145@outlook.com
                  </a>
                </li>
              </ul>

              <strong className="text-xl font-semibold" id="sub">
                Skills
              </strong>
              <ul className="mt-2 mb-10">
                <li className="px-2 mt-1">Organizational Skills</li>
                <li className="px-2 mt-1">Team Player</li>
                <li className="px-2 mt-1">An eye for trends and detail,</li>
                <li className="px-2 mt-1">Good at research</li>
                <li className="px-2 mt-1">Adobe Creative Suite</li>
                <li className="px-2 mt-1">Leadership</li>
                <li className="px-2 mt-1">Powerpoint</li>
                <li className="px-2 mt-1">Time Management</li>
                <li className="px-2 mt-1">Sewing</li>
                <li className="px-2 mt-1">Pattern making</li>
              </ul>
              <strong className="text-xl font-semibold" id="sub">
                Further Education
              </strong>
              <ul className="mt-2 mb-10">
                <li className="px-2 mt-1">Third year university</li>
              </ul>

              <strong className="text-xl font-semibold" id="sub">
                Interests & Hobbies
              </strong>
              <ul className="mt-2">
                <li className="px-2 mt-1">Sewing</li>
                <li className="px-2 mt-1">Discovering new location</li>
                <li className="px-2 mt-1">Drawing & painting</li>
                <li className="px-2 mt-1">
                  gouache, acrylic, and watercolour paints.
                </li>
              </ul>
            </div>
            <div className="w-4/6">
              <section>
                <h2 className="text-2xl pb-1 border-b font-semibold" id="sub">
                  Summary
                </h2>
                <p className="mt-4 text-sm">
                  Proficient with a variety of photographic tools and equipment,
                  including cameras, editing software, Photoshop, Adobe
                  Illustration.
                </p>
              </section>

              <section>
                <h2
                  className="text-2xl mt-6 pb-1 border-b font-semibold"
                  id="sub"
                >
                  Work Experiences
                </h2>
                <ul className="mt-2">
                  <li className="pt-2">
                    <p className="flex justify-between text-sm">
                      <strong className="text-base">Fashion Designer</strong>
                      2019-2021
                    </p>
                    <p className="flex justify-between text-base">
                      Assistant
                      <small>
                        Barcode Fashion Leicester • Leicester, Leicestershire
                      </small>
                    </p>
                    <p className="text-justify text-sm pt-2">
                      Packing, pressing, and photographing samples, as well as
                      assisting designers with research and organizing pattern
                      papers and sheets. Working with designers on upcoming
                      designs for brands such as Boohoo, Dorothy Perkins, and
                      Pretty Little Thing.
                    </p>
                  </li>
                </ul>
              </section>
              <section>
                <h2
                  className="text-2xl mt-6 pb-1 border-b font-semibold"
                  id="sub"
                >
                  Education
                </h2>
                <ul className="mt-2">
                  <li className="pt-2">
                    <p className="flex justify-between text-sm">
                      <strong className="text-2xl">
                        Soar Valley College • Leicester, Leicestershire
                      </strong>
                      06/2017
                    </p>
                    <p className="text-md font-semibold pt-2 pb-2">
                      Graphics, French, History, Home Economics, Maths, English
                    </p>
                    <p className="flex justify-between text-sm">
                      English literature/language 5 <br /> Maths 4 <br />
                      Additional Science B <br />
                      Science C <br /> Graphics B <br /> Religious Studies C{" "}
                      <br />
                      French C <br /> History C <br /> Home Economics(Food and
                      Nutrition) B
                    </p>
                  </li>
                  <li className="pt-2">
                    <p className="flex justify-between text-sm">
                      <strong className="text-2xl">
                        Gateway College Leicester • Leicester, Leicestershire
                      </strong>
                      05/2019
                    </p>
                    <p className="text-md font-semibold pt-2 pb-2">
                      Art and Design Level 3
                    </p>
                    <p className="flex justify-between text-sm">
                      Grades <br /> Year 1 D* D <br />
                      Year 2 D* DD
                    </p>
                  </li>
                </ul>
              </section>
              <section className="mt-6">
                <h2 className="text-2xl pb-1 border-b font-semibold" id="sub">
                  Achievements
                </h2>
                <p className="mt-4 text-sm">
                  In 2019, my fashion garment was featured in the Leicester
                  Mercury newspaper. In 2019, my digital artwork was on show at
                  Leicester's Museum and Art Gallery.
                </p>
              </section>
              <section className="mt-6">
                <h2 className="text-2xl pb-1 border-b font-semibold" id="sub">
                  Interests
                </h2>
                <p className="mt-4 text-sm">
                  I enjoy sewing and producing items, as well as developing new
                  outfits to wear. I enjoy sewing and making new items out of
                  old fabrics and clothing. I enjoy discovering new locations
                  and learning about different cultures. I enjoy taking images
                  of landscape that appeals to my eyes wherever I go. My hobby
                  is drawing and painting, especially using gouache, acrylic,
                  and watercolour paints.
                </p>
              </section>
              <section className="mt-6">
                <h2 className="text-2xl pb-1 border-b font-semibold" id="sub">
                  Languages
                </h2>
                <p className="mt-4 text-sm">English, Gujarati, Hindi</p>
              </section>
            </div>
          </main>
        </div>
      </body>
    </div>
  );
}

export default CV;
