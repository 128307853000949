import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";
// import images

function Gallery() {
  const imageUrls = [
    "https://i.postimg.cc/T14y1tfJ/10.jpg",
    "https://i.postimg.cc/5NW4rrqw/portfoliopage5.jpg",
    "https://i.postimg.cc/CK2Ysrxn/portfoliopage6.jpg",
    "https://i.postimg.cc/tgZ8pq97/portfoliopage8.jpg",
    "https://i.postimg.cc/4N28dDLc/portfoliopage9.jpg",
    "https://i.postimg.cc/g0qmLCSY/4.jpg",
    "https://i.postimg.cc/YqhrDy93/5.jpg",
    "https://i.postimg.cc/NjXgB9R2/6.jpg",
    "https://i.postimg.cc/Rh8Bz4FT/8.jpg",
    "https://i.postimg.cc/Gp1b2Lj7/9.jpg",
    "https://i.postimg.cc/vHjdDryH/IMG_4190-removebg-preview.png",
    "https://i.postimg.cc/Kzv0bWRq/IMG_3526-removebg-preview.png",
    "https://i.postimg.cc/bw0XNKwp/portfoliopage3.jpg",
    "https://i.postimg.cc/8kqPFgN6/portfoliopage4.jpg",
    "https://i.postimg.cc/TYg2gN3H/portfoliopage5.jpg",
    "https://i.postimg.cc/QdD8bgBN/portfoliopage8.jpg",
    "https://i.postimg.cc/g0DP41CN/portfoliopage9.jpg",
    "https://i.postimg.cc/KvpXKnns/IMG_3148.jpg",
    "https://i.postimg.cc/mZNBrzX0/IMG_3151.jpg",
    "https://i.postimg.cc/9QSjkG0q/IMG_3158.jpg",
    "https://i.postimg.cc/NFp648YK/11.jpg",
    "https://i.postimg.cc/Kz5BG710/12.jpg",
    "https://i.postimg.cc/brz0kVXX/2.jpg",
    "https://i.postimg.cc/3Jfj0Zn6/6.jpg",
    "https://i.postimg.cc/PqsSN4qH/1.jpg",
    "https://i.postimg.cc/5Ny7ryrh/4.jpg",
    "https://i.postimg.cc/jSJ8s9tv/cad-01.jpg",
    "https://i.postimg.cc/x1SmzFL6/finallineup1.jpg",
    "https://i.postimg.cc/fyG958pT/finallineup2.jpg",
    "https://i.postimg.cc/9M1b8HYX/IMG_4708-removebg-preview.png",
    "https://i.postimg.cc/rpk9qwhF/IMG_4753-removebg-preview.png",
    "https://i.postimg.cc/0N00j13G/IMG_4761-removebg-preview.png",
    "https://i.postimg.cc/gjF8mYqH/IMG_6435.jpg",
    "https://i.postimg.cc/cJLcb4XK/IMG_6438.jpg",
    "https://i.postimg.cc/jdTP1Sjs/IMG_6440.jpg",
    "https://i.postimg.cc/MT0NzL0g/thumbnail_IMG_4900-removebg-preview.png",
    "https://i.postimg.cc/mkbqkS8k/thumbnail_IMG_4907-removebg-preview.png",
    "https://i.postimg.cc/WbYKpgrp/thumbnail_IMG_4933-removebg-preview.png",
    "https://i.postimg.cc/3wT97Lgq/1.jpg",
    "https://i.postimg.cc/qv60WGrS/1.jpg",
    "https://i.postimg.cc/m2d0L1V4/1.jpg",
    "https://i.postimg.cc/C1dhK40G/1_(2).png",
    "https://i.postimg.cc/N0dXVbbm/1_(2).jpg",
    "https://i.postimg.cc/CMqZHFyj/1_(3).jpg",
    "https://i.postimg.cc/Vvxng6gh/10.jpg",
    "https://i.postimg.cc/446LdHfM/10.jpg",
    "https://i.postimg.cc/R0Vbrs1T/10.jpg",
    "https://i.postimg.cc/brMm1p4C/10_(3).png",
    "https://i.postimg.cc/zGB00dCZ/11.jpg",
    "https://i.postimg.cc/D0ntZqtv/11.png",
    "https://i.postimg.cc/mZ1cN7zX/11.jpg",
    "https://i.postimg.cc/L4nw88YF/11.jpg",
    "https://i.postimg.cc/yNvXF7Zm/12.jpg",
    "https://i.postimg.cc/hGbFqy5y/12.jpg",
    "https://i.postimg.cc/XJ6RRcJN/12.jpg",
    "https://i.postimg.cc/tR6TVyw8/13.jpg",
    "https://i.postimg.cc/B6b5pRTd/13.jpg",
    "https://i.postimg.cc/RVyx1ntR/13.jpg",
    "https://i.postimg.cc/qM3L9fPr/13_(2).jpg",
    "https://i.postimg.cc/pLzY0gmS/14.jpg",
    "https://i.postimg.cc/dVCP5tmK/14.jpg",
    "https://i.postimg.cc/RCj83T9M/14.jpg",
    "https://i.postimg.cc/CLYFB8jf/15.jpg",
    "https://i.postimg.cc/g2pTsknQ/15.jpg",
    "https://i.postimg.cc/635rpqMs/15.jpg",
    "https://i.postimg.cc/sXM6xMNQ/15_(2).png",
    "https://i.postimg.cc/526SwXJf/16.jpg",
    "https://i.postimg.cc/2y1nnJp2/16.jpg",
    "https://i.postimg.cc/T3HnwtM0/16.jpg",
    "https://i.postimg.cc/1Rp0H0wR/16_(2).png",
    "https://i.postimg.cc/G2vDXrBg/17.png",
    "https://i.postimg.cc/SNQrxZt2/17.jpg",
    "https://i.postimg.cc/dtS8tJyp/18.jpg",
    "https://i.postimg.cc/63NVJzvQ/19.jpg",
    "https://i.postimg.cc/HxhyW2J5/19.jpg",
    "https://i.postimg.cc/JnJ1Jzy0/2.jpg",
    "https://i.postimg.cc/8kXHsd8Z/2.jpg",
    "https://i.postimg.cc/CLZR7kRr/2.jpg",
    "https://i.postimg.cc/13fbfRYq/2.jpg",
    "https://i.postimg.cc/pTkssD56/2.jpg",
    "https://i.postimg.cc/P5h850R1/2_(2).jpg",
    "https://i.postimg.cc/3xKdTmkB/2_(3).png",
    "https://i.postimg.cc/7ZYYc4Rt/2_(4).jpg",
    "https://i.postimg.cc/RZLGSkvH/20.jpg",
    "https://i.postimg.cc/jjLQQGQB/20.jpg",
    "https://i.postimg.cc/KzNTXbb9/21.jpg",
    "https://i.postimg.cc/MH1VsBTQ/21.jpg",
    "https://i.postimg.cc/vm11qn2Z/23.jpg",
    "https://i.postimg.cc/hjcBzWmR/23.jpg",
    "https://i.postimg.cc/65XcJbtG/25.jpg",
    "https://i.postimg.cc/d0MvFTt1/26.jpg",
    "https://i.postimg.cc/3NHXhW5H/27.jpg",
    "https://i.postimg.cc/jSPmz3tx/28.jpg",
    "https://i.postimg.cc/3JYXbyFX/28.jpg",
    "https://i.postimg.cc/T1HM4K4T/29.jpg",
    "https://i.postimg.cc/HxhyW2J5/19.jpg",
    "https://i.postimg.cc/CLZR7kRr/2.jpg",
    "https://i.postimg.cc/P5h850R1/2_(2).jpg",
    "https://i.postimg.cc/3xKdTmkB/2_(3).png",
    "https://i.postimg.cc/7ZYYc4Rt/2_(4).jpg",
    "https://i.postimg.cc/vm11qn2Z/23.jpg",
    "https://i.postimg.cc/hjcBzWmR/23.jpg",
    "https://i.postimg.cc/65XcJbtG/25.jpg",
    "https://i.postimg.cc/d0MvFTt1/26.jpg",
    "https://i.postimg.cc/3NHXhW5H/27.jpg",
    "https://i.postimg.cc/jSPmz3tx/28.jpg",
    "https://i.postimg.cc/3JYXbyFX/28.jpg",
    "https://i.postimg.cc/T1HM4K4T/29.jpg",
    "https://i.postimg.cc/CLCzXmx5/2piece.jpg",
    "https://i.postimg.cc/xdvxzrfY/3.jpg",
    "https://i.postimg.cc/kX8nDc6C/3.jpg",
    "https://i.postimg.cc/qBZbPxgs/3.jpg",
    "https://i.postimg.cc/brFBLQSR/3_(3).jpg",
    "https://i.postimg.cc/BZ80jgL6/31.jpg",
    "https://i.postimg.cc/Cx0T6pvB/32.jpg",
    "https://i.postimg.cc/d3fp9zh1/33.jpg",
    "https://i.postimg.cc/3rfs7DtT/34.jpg",
    "https://i.postimg.cc/J7cJJrCG/33.jpg",
    "https://i.postimg.cc/8PKP256J/35.jpg",
    "https://i.postimg.cc/1RKzKMRK/36.jpg",
    "https://i.postimg.cc/Nj429b3S/36.jpg",
    "https://i.postimg.cc/FK314p97/37.jpg",
    "https://i.postimg.cc/43JjfNGV/38.jpg",
    "https://i.postimg.cc/wvdrfXxX/4.jpg",
    "https://i.postimg.cc/mZzpGpJb/4.jpg",
    "https://i.postimg.cc/W105JR88/4.jpg",
    "https://i.postimg.cc/wTrGKc4K/4.jpg",
    "https://i.postimg.cc/zB7MHmJ6/4_(3).jpg",
    "https://i.postimg.cc/xdjJK7MM/41.jpg",
    "https://i.postimg.cc/XYtNvzxn/43.jpg",
    "https://i.postimg.cc/hvjSf1q8/43.jpg",
    "https://i.postimg.cc/KzK6wGtb/45.jpg",
    "https://i.postimg.cc/ydx5RQkN/49.jpg",
    "https://i.postimg.cc/6pvzk8mY/5.png",
    "https://i.postimg.cc/LXBbrJhr/5.jpg",
    "https://i.postimg.cc/qM9W6HfD/5.jpg",
    "https://i.postimg.cc/jdYp3r2V/5.jpg",
    "https://i.postimg.cc/4y30z8yd/5.jpg",
    "https://i.postimg.cc/3NdF9zTk/5.jpg",
    "https://i.postimg.cc/fLsn0jYt/6.jpg",
    "https://i.postimg.cc/tJqMy9x7/6.jpg",
    "https://i.postimg.cc/VNvp0fGt/6.jpg",
    "https://i.postimg.cc/cLQMDQVB/7.jpg",
    "https://i.postimg.cc/SKxV1gcb/7_(2).jpg",
    "https://i.postimg.cc/j5tmjqry/7_(3).png",
    "https://i.postimg.cc/X7FC39nG/8.jpg",
    "https://i.postimg.cc/ry31MnT9/8.jpg",
    "https://i.postimg.cc/5yhkZ8bq/8.jpg",
    "https://i.postimg.cc/wMz7jGq1/8.jpg",
    "https://i.postimg.cc/rwVxJKLD/8.jpg",
    "https://i.postimg.cc/ydpjz761/8.jpg",
    "https://i.postimg.cc/d3j4SpNv/9.jpg",
    "https://i.postimg.cc/m2RHLtkm/9.jpg",
    "https://i.postimg.cc/XJhCNJRv/9.jpg",
    "https://i.postimg.cc/zft2qC0Y/9.jpg",
    "https://i.postimg.cc/jjk5yjsY/aaaaa.jpg",
    "https://i.postimg.cc/JnDhxWnD/aaaaaa.jpg",
    "https://i.postimg.cc/B6rnwwLh/aaaaaaa.jpg",
    "https://i.postimg.cc/zXSG07Jj/bb.jpg",
    "https://i.postimg.cc/Jzr4gX0W/bbbb.jpg",
    "https://i.postimg.cc/VkKLcN00/bbbbb.jpg",
    "https://i.postimg.cc/Jzr4gX0W/bbbb.jpg",
    "https://i.postimg.cc/q7YJzZLk/bbbbbbb.jpg",
    "https://i.postimg.cc/Sx5zqjCt/cad1.jpg",
    "https://i.postimg.cc/mD3cQwq0/cad2.jpg",
    "https://i.postimg.cc/D0jwgYcY/cc.jpg",
    "https://i.postimg.cc/HkvHhPFQ/ccc.jpg",
    "https://i.postimg.cc/cLL0TNkx/cccc.jpg",
    "https://i.postimg.cc/CMtw7tTk/IMG_2058.jpg",
    "https://i.postimg.cc/fLVRThQW/IMG_2060.jpg",
    "https://i.postimg.cc/rwgV1p8F/IMG_2061.jpg",
    "https://i.postimg.cc/1RN3kPKJ/IMG_2064.jpg",
    "https://i.postimg.cc/MTfzJb1v/IMG_2068.jpg",
    "https://i.postimg.cc/MHkq6MYt/IMG_2072.jpg",
    "https://i.postimg.cc/vZbktLB5/IMG_2149.png",
    "https://i.postimg.cc/SKtP4BGG/IMG_2151.png",
    "https://i.postimg.cc/d00fzsG1/IMG_2153.png",
    "https://i.postimg.cc/k4fk2V9W/IMG_2251.jpg",
    "https://i.postimg.cc/0NB94HMT/IMG_3518.jpg",
    "https://i.postimg.cc/j5xjVJZf/IMG_3519.jpg",
    "https://i.postimg.cc/cHFLd4bb/IMG_3521.jpg",
    "https://i.postimg.cc/CMbcrB97/IMG_3524.jpg",
    "https://i.postimg.cc/LsqTtnzz/IMG_3528.jpg",
    "https://i.postimg.cc/JnVbjK7L/IMG_3529.jpg",
    "https://i.postimg.cc/YqqQNv7h/IMG_3532.jpg",
    "https://i.postimg.cc/Nfz21bbz/IMG_3535.jpg",
    "https://i.postimg.cc/Hk8c35hs/IMG_3537.jpg",
    "https://i.postimg.cc/0NRDDbjK/long_sleeve.jpg",
    "https://i.postimg.cc/QMJ97mvv/offtheshoulder.jpg",
    "https://i.postimg.cc/L5SMPdZM/pattern.jpg",
    "https://i.postimg.cc/kMNCm5kM/pattern2.jpg",
    "https://i.postimg.cc/bJ6ZCJyT/sabya.jpg",
    "https://i.postimg.cc/85kFmWSc/short_sleeve.jpg",
    "https://i.postimg.cc/DySXNqbb/Slide1.jpg",
    "https://i.postimg.cc/nLJ9MgSv/Slide2.jpg",
    "https://i.postimg.cc/L4bqjn04/Slide3.jpg",
    "https://i.postimg.cc/kXR2sqkQ/Slide4.jpg",
    "https://i.postimg.cc/pLNp13Hg/Slide5.jpg",
    "https://i.postimg.cc/W1vJ4t49/work1.jpg",
    "https://i.postimg.cc/qMfNmg2k/work12.jpg",
    "https://i.postimg.cc/jdzLBKhy/work15.jpg",
    "https://i.postimg.cc/4dmY0983/work2.jpg",
    "https://i.postimg.cc/W1ThvK9C/work13.jpg",
  ];

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="p-12">
      <Helmet>
        <title>nisha - gallery </title>
      </Helmet>
      <h1 className="text-9xl text-gray-900 font-bold mx-auto pb-2" id="header">
        <span>Gallery</span>
      </h1>
      <Masonry imageUrls={imageUrls} columnCount="3" gap="5"></Masonry>
    </div>
  );
}

export default Gallery;
