import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "./Masonry.js";
import { Link, NavLink } from "react-router-dom";

function College() {
  const imageUrls = [
    "https://i.postimg.cc/3wT97Lgq/1.jpg",
    "https://i.postimg.cc/52M8FX6Y/10.jpg",
    "https://i.postimg.cc/mgW8MKDy/8.jpg",
    "https://i.postimg.cc/XJ6RRcJN/12.jpg",
    "https://i.postimg.cc/pTkssD56/2.jpg",
    "https://i.postimg.cc/xdvxzrfY/3.jpg",
    "https://i.postimg.cc/wTrGKc4K/4.jpg",
    "https://i.postimg.cc/3NdF9zTk/5.jpg",
    "https://i.postimg.cc/cLQMDQVB/7.jpg",
    "https://i.postimg.cc/XJhCNJRv/9.jpg",
    "https://i.postimg.cc/QMt4x1C2/11.jpg",
  ];

  const morework = [
    "https://i.postimg.cc/BZkWT05f/DSC-0001.jpg",
    "https://i.postimg.cc/L4Y4KvNR/DSC-0002.jpg",
    "https://i.postimg.cc/nrDQXxxG/DSC-0007.jpg",
    "https://i.postimg.cc/J7PNvr6S/DSC-0008.jpg",
    "https://i.postimg.cc/D0qy5F8n/DSC-0003.jpg",
    "https://i.postimg.cc/wj93ftXP/DSC-0004.jpg",
    "https://i.postimg.cc/J4NG53kZ/DSC-0005.jpg",
    "https://i.postimg.cc/nrYpBkPL/DSC-0006.jpg",
    "https://i.postimg.cc/QM4BWXWz/DSC-0009.jpg",
    "https://i.postimg.cc/wMKjh3xw/DSC-0010.jpg",
    "https://i.postimg.cc/R0V6F3qf/DSC-0011.jpg",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lg:p-14 p-2">
      <Helmet>
        <title>nisha - college</title>
      </Helmet>
      <h1
        className="text-9xl text-gray-900 font-bold text-right pb-2 tracking-tight mb-8"
        id="me"
        data-aos="slide-down"
        data-aos-duration="800"
      >
        <span>College</span>
      </h1>
      <Masonry imageUrls={imageUrls} columnCount="3" gap="5"></Masonry>
      <br />
      <Masonry imageUrls={morework} columnCount="2" gap="5"></Masonry>
      <br />
      <div className="grid justify-center mx-auto">
        <img
          src="https://i.postimg.cc/6qxCTjC1/Slide1.jpg"
          alt=""
          data-aos="slide-up"
          data-aos-duration="1500"
        />
      </div>
    </div>
  );
}

export default College;
