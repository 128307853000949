import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// components
import Home from "./components/pages/Home";
import Gallery from "./components/pages/Gallery";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import CV from "./components/pages/CV";
import CAD from "./components/pages/CAD";
import YearOne from "./components/pages/YearOne";
import YearTwo from "./components/pages/YearTwo";
import YearThree from "./components/pages/YearThree";
import Major from "./components/pages/Major";
import SummerProject from "./components/pages/SummerProject";
import Knitwear from "./components/pages/Knitwear";
import FinalOutcomes from "./components/pages/FinalOutcomes";
import KnitToile from "./components/pages/KnitToile";
import Standwork from "./components/pages/Standwork";
import College from "./components/pages/College";
import One from "./components/pages/standwork/One";
import Two from "./components/pages/standwork/Two";
import Three from "./components/pages/standwork/Three";
import Portfolios from "./components/pages/Portfolios";
import Exhibitions from "./components/pages/Exhibitions";
import Four from "./components/pages/standwork/Four";
import Personal from "./components/pages/Personal";
import CVimage from "./components/pages/CVimage";

function App() {
  return (
    // <HashRouter>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/CAD" element={<CAD />} />
        <Route exact path="/first-year" element={<YearOne />} />
        <Route exact path="/second-year" element={<YearTwo />} />
        <Route exact path="/third-year" element={<YearThree />} />
        <Route exact path="/major-project" element={<Major />} />
        <Route exact path="/summer-project" element={<SummerProject />} />
        <Route exact path="/knitwear" element={<Knitwear />} />
        <Route exact path="/knitwear-toile" element={<KnitToile />} />
        <Route exact path="/final-outcomes" element={<FinalOutcomes />} />
        <Route exact path="/cv" element={<CV />} />
        <Route exact path="/standwork" element={<Standwork />} />
        <Route exact path="/college" element={<College />} />
        <Route exact path="/standwork/1" element={<One />} />
        <Route exact path="/standwork/2" element={<Two />} />
        <Route exact path="/standwork/3" element={<Three />} />
        <Route exact path="/standwork/4" element={<Four />} />
        <Route exact path="/portfolios" element={<Portfolios />} />
        <Route exact path="/exhibitions" element={<Exhibitions />} />
        <Route exact path="/personal-project" element={<Personal />} />
        <Route exact path="/mycv" element={<CVimage />} />
        <Route path="*" element={<Home to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    // </HashRouter>
  );
}

export default App;
