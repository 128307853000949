import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="lg:p-24 p-4">
      <Helmet>
        <title>nisha - contact me</title>
      </Helmet>
      <div className="lg:pt-4 pt-16 flex flex-col lg:items-left lg:justify-start items-center justify-center">
        <h1
          className="text-9xl text-gray-900 font-black pb-2 lg:text-left text-center"
          data-aos="slide-right"
          data-aos-duration="800"
          id="header"
        >
          <span>Contact me</span>
        </h1>
        <a
          className="text-2xl text-purple-500 text-center"
          data-aos="slide-right"
          data-aos-duration="1000"
          href="mailto:nishaa2145@outlook.com"
        >
          <i className="fa-solid fa-envelope text-purple-400"></i>{" "}
          nishaa2145@outlook.com
        </a>
      </div>
      <div class="grid grid-rows-3 grid-flow-col gap-4 min-h-screen lg:p-20 p-0">
        <div class="row-span-3 lg:block hidden">
          <div class="row-start-1 col-start-2 col-span-2 transform translate-x-20 translate-y-4">
            <img
              src="https://i.postimg.cc/Vk9GxHhP/placementprint.jpg"
              alt=""
              loading="lazy"
              data-aos="slide-down"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div class="row-span-2 col-span-2 lg:pt-1 pt-10">
          <div class="w-full px-4">
            <div
              class="bg-white relative rounded-lg lg:p-8 p-4 shadow-lg"
              data-aos="slide-up"
              data-aos-duration="1000"
            >
              <form>
                <div class="mb-6">
                  <input
                    type="text"
                    placeholder="Your Name"
                    class="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="email"
                    placeholder="Your Email"
                    class="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="text"
                    placeholder="Your Phone"
                    class="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                  />
                </div>
                <div class="mb-6">
                  <textarea
                    rows="6"
                    placeholder="Your Message"
                    class="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    class="
                        w-full
                        text-white
                        bg-purple-500/70
                        rounded
                        border border-purple-400
                        p-3
                        transition
                        hover:bg-purple-600/80
                        "
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
