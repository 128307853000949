import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      {showSidebar ? (
        <button
          className="flex text-4xl text-white items-center cursor-pointer fixed left-10 top-6 z-50"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          x
        </button>
      ) : (
        <i
          onClick={() => setShowSidebar(!showSidebar)}
          class="text-gray-900 fixed z-30 flex items-center cursor-pointer left-10 top-6 text-4xl fa-solid fa-bars"
        ></i>
      )}

      <div
        className={`top-0 left-0 sm:w-full md:w-[42vw] xl:w-[30vw] bg-purple-500 p-10 pl-24 text-white fixed h-full z-40  ease-in-out duration-300 ${
          showSidebar ? "translate-x-0 " : "-translate-x-full"
        }`}
      >
        <h3 className="mt-20 text-7xl font-extrabold text-white title hover:text-purple-100/90">
          <NavLink to="/" onClick={() => setShowSidebar(!showSidebar)}>
            nisha solanki
          </NavLink>
        </h3>
        <br />

        <div className="navitems">
          <NavLink
            to="/"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Home
          </NavLink>
          <br />
          <NavLink
            to="/cad"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            CAD
          </NavLink>
          {/* <br />
          <NavLink
            to="/gallery"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Gallery
          </NavLink> */}
          <br />
          <NavLink
            to="/college"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            College
          </NavLink>

          <br />
          <NavLink
            to="/standwork"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Standwork
          </NavLink>

          <br />
          <NavLink
            to="/first-year"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Uni 1st year
          </NavLink>
          <br />
          <NavLink
            to="/second-year"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Uni 2nd year
          </NavLink>
          <br />
          <NavLink
            to="/third-year"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Uni 3rd year
          </NavLink>
          <br />
          <NavLink
            to="/major-project"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Major project
          </NavLink>
          <br />
          <NavLink
            to="/summer-project"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Summer project
          </NavLink>
          <br />
          <NavLink
            to="/final-outcomes"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Final outcomes
          </NavLink>
          <br />
          <NavLink
            to="/knitwear-toile"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Knitwear Toile
          </NavLink>
          <br />
          <NavLink
            to="/knitwear"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Knitwear
          </NavLink>
          <br />

          <NavLink
            to="/portfolios"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Portfolios
          </NavLink>
          <br />
          <NavLink
            to="/exhibitions"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Exhibitions
          </NavLink>
          <br />
          <NavLink
            to="/personal-project"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Personal Project
          </NavLink>
          <br />
          <br />
          <NavLink
            to="/about"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            About
          </NavLink>
          <br />
          <NavLink
            to="/contact"
            onClick={() => setShowSidebar(!showSidebar)}
            className={({ isActive }) => (isActive ? "bg-purple-400 px-4" : "")}
          >
            Contact
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Navbar;
