import React from "react";
import { Helmet } from "react-helmet";

function CVimage() {
  return (
    <div>
      <Helmet>
        <title>nisha - my cv</title>
      </Helmet>
      <div class="gap-4 grid-cols-2 flex h-screen justify-center items-center mx-auto">
        <div>
          <div class="mx-auto">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/8zB884F6/nisha-cv-pages-to-jpg-0001.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div>
          <div class="mx-auto">
            <img
              class="shadow-lg"
              src="https://i.postimg.cc/Qx7GmhhS/nisha-cv-pages-to-jpg-0002.jpg"
              alt="portfolio"
              data-aos="slide-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CVimage;
